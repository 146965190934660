import React, { useCallback } from "react";
import * as Tone from "tone";
import { ContextProviderProps } from "./InputsContext";
import { useKeyPress } from "../hooks/UseKeyPress";

export interface PlayerContextProps {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  volume: number;
  setVolume: React.Dispatch<React.SetStateAction<number>>;
  mute: boolean;
  setMute: React.Dispatch<React.SetStateAction<boolean>>;
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
  cleanUpUniPlayer: () => void;
  currentPlayer?: Tone.Player;
  setCurrentPlayer: React.Dispatch<
    React.SetStateAction<Tone.Player | undefined>
  >;
  timeProgressBar: React.RefObject<HTMLInputElement>;
  togglePlayPause: () => void;
}

export const PlayerContext = React.createContext<null | PlayerContextProps>(
  null
);

export const PlayerContextProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [volume, setVolume] = React.useState(0);
  const [currentPlayer, setCurrentPlayer] = React.useState<
    undefined | Tone.Player
  >(undefined);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [mute, setMute] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const timeProgressBar = React.useRef<HTMLInputElement>(null);

  useKeyPress(
    [" "],
    () => {
      setIsPlaying(!isPlaying);
    },
    [32]
  );

  const togglePlayPause = React.useCallback(() => {
    if (currentPlayer === undefined || !timeProgressBar.current) return;
    if (isPlaying) {
      if (Tone.context.state !== "running") {
        Tone.start();
      }
      Tone.Transport.start();
    } else {
      Tone.Transport.pause();
    }
  }, [isPlaying, currentPlayer]);

  const cleanUpUniPlayer = useCallback(() => {
    if (currentPlayer) {
      if (currentPlayer.state === "started") {
        currentPlayer.stop();
      }
      currentPlayer.disconnect();
      currentPlayer.dispose();
    }
    Tone.Transport.stop();
    Tone.Transport.seconds = 0;
    setCurrentPlayer(undefined);
  }, [currentPlayer]);

  const contextValue = {
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
    mute,
    setMute,
    currentTime,
    setCurrentTime,
    cleanUpUniPlayer,
    currentPlayer,
    setCurrentPlayer,
    timeProgressBar,
    togglePlayPause,
  };

  return (
    <PlayerContext.Provider value={contextValue}>
      {children}
    </PlayerContext.Provider>
  );
};
