import SourceSepGif from "../../assets/gifs/source-sep.gif";
import "./NotFoundPage.css";

interface NotFoundProps {
  text?: string;
}

const NotFoundPage: React.FC<NotFoundProps> = ({text}) => {
  return (
    <div className="not-found-container">
      <div className="not-found-page">
        <img
          src={SourceSepGif}
          alt="source-sep-gif"
          className="not-found-icon"
        />
        <div className="generic-xl-bold-text">{text ?? "404 Not found"}</div>
      </div>
    </div>
  );
};

export default NotFoundPage;
