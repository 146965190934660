import { Outlet } from "react-router-dom"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"

export const Layout = () => {
    return (
        <div style={{height: "100%", width: "100%"}}>
            <Header/>
            <div style={{height: "78%"}} className="flex items-center"><Outlet /></div>
            <Footer/>
        </div>
    )
}