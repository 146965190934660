import React, { FC, useEffect, useRef, useState } from "react";
import "./WaveformContainer.css";
import WaveForm from "./WaveForm";
import { theme } from "../../constants/theme";
import Slider from "@mui/material/Slider";
import * as Tone from "tone";

interface WaveformContainerProps {
  audioBuffer: AudioBuffer;
  drawAmplitudeLines?: boolean;
  inpaintingMode?: boolean;
  color: string;
  isUsingTone?: boolean;
  currentTime?: number;
  onChangeSliderValue: (value: number) => void;
  zoom?: number;
  isBasslineElement?: boolean;
  index?: number;
}

const WaveformContainer: FC<WaveformContainerProps> = ({
  audioBuffer,
  drawAmplitudeLines = true,
  inpaintingMode = false,
  currentTime,
  color,
  isUsingTone = false,
  onChangeSliderValue,
  zoom = 1,
  isBasslineElement = false,
  index = 0,
}) => {
  const ProgressCursorBarRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<number>(0);
  const [audioDuration, setAudioDuration] = useState<number>(0);

  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number>(0);
  const [parentHeight, setParentHeight] = useState<number>(0);

  useEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
      setParentHeight(parentRef.current.offsetHeight);
    }
  }, []);

  const onChangeSlider = (value: number) => {
    setValue(value);
    onChangeSliderValue(value);
  };

  // range for inpainting
  const [range, setRange] = React.useState<number[]>([20, 37]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setRange(newValue as number[]);
  };

  // keep in mind that value is a percentage not a duration
  useEffect(() => {
    if (ProgressCursorBarRef.current) {
      ProgressCursorBarRef.current.style.backgroundSize = `${value}% 100%`;
    }
  }, [value, inpaintingMode]);

  useEffect(() => {
    if (audioBuffer) {
      setAudioDuration(audioBuffer.duration);
    }
  }, [audioBuffer]);

  useEffect(() => {
    if (currentTime) {
      setValue((currentTime / audioBuffer.duration) * 100);
    } else {
      setValue(0);
    }
  }, [currentTime, audioBuffer?.duration]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (audioBuffer === undefined) return;
    const duration = audioDuration;
    intervalId = setInterval(() => {
      if (ProgressCursorBarRef.current && !inpaintingMode && isUsingTone) {
        const currentTime = (Tone.Transport.seconds / duration) * 100;
        if (currentTime > 99) {
          setValue(100);
        } else setValue(currentTime);
      }
    }, 16);
    return () => {
      clearInterval(intervalId);
    };
  }, [audioDuration, audioBuffer, inpaintingMode, isUsingTone]);

  return (
    <div className="waveform-container">
      <div
        className="overlayed-waveform-container"
        id={"overlayed-waveform-container-" + index}
        style={{ width: `${100 * zoom}%` }}
        ref={parentRef}
      >
        <WaveForm
          audioBuffer={audioBuffer}
          width={parentWidth * zoom}
          height={parentHeight / 2}
          color={color}
          drawAmplitudeLines={drawAmplitudeLines}
          isBasslineElement={isBasslineElement}
          channel={0}
        />
        <WaveForm
          audioBuffer={audioBuffer}
          width={parentWidth * zoom}
          height={parentHeight / 2}
          color={color}
          drawAmplitudeLines={drawAmplitudeLines}
          isBasslineElement={isBasslineElement}
          channel={audioBuffer.numberOfChannels > 1 ? 1 : 0}
        />
      </div>
      <div
        className="waveform-progress-cursor-bar-container"
        style={{ width: zoom === 1 ? "100%" : `${100 * zoom}%` }}
      >
        {!inpaintingMode ? (
          <input
            ref={ProgressCursorBarRef}
            type="range"
            value={isNaN(value) ? 0 : value}
            className="progress-cursor-input-bar"
            onChange={(e) => onChangeSlider(Number(e.target.value))}
            style={{ backgroundImage: `linear-gradient(${color}, ${color})` }}
            step="any"
          />
        ) : (
          <Slider
            value={range}
            onChange={handleChange}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
            sx={{
              width: "100%",
              height: "100%",
              zIndex: 1,
              color: theme.palette.white, // thunm bar color
              "& .MuiSlider-rail": {
                height: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "transparent",
              },
              "& .MuiSlider-track": {
                height: "100%",
                borderRadius: "0.5rem",
                backgroundColor: color,
                opacity: 0.5,
              },
              "& .MuiSlider-thumb": {
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: theme.palette.white,
                opacity: 0.5,
                border: "0.1rem solid",
                borderColor: "transparent",
                // color: theme.palette.pink,
                "&:hover": {
                  boxShadow: "0 0 0 0.05rem",
                  baxShadowColor: "transparent",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //backgroundColor: theme.palette.pink,
                },
                "&:focus": {
                  boxShadow: "0 0 0 0.25rem currentColor",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //color: theme.palette.pink,
                },
                "&.Mui-active": {
                  boxShadow: "0 0 0 0.25rem currentColor",
                  color: theme.palette.lightGrey,
                  opacity: 0.5,
                  //color: theme.palette.pink,
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default WaveformContainer;
