import React, { useEffect, useState, useRef } from "react";
import "./OutputTrack.css";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import { theme } from "../../constants/theme";
import downloadIcon from "../../assets/icons/download-icon.svg";
import { VolumeMute } from "@mui/icons-material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import WaveformContainer from "../Waveform/WaveformContainer";
import * as Tone from "tone";
import {
  MultiPlayerContext,
  IMultiPlayerContext,
} from "../../contexts/MultiPlayerContext";
import { useKeyPress } from "../../hooks/UseKeyPress";
import saveAs from "file-saver";

interface OutputTrackProps {
  typeName?: string;
  zoom?: number;
  index?: number;
  audioBuffer?: AudioBuffer;
  selected?: boolean;
  name: string;
  blob: Blob;
  color?: string;
  originalFileName?: string;
}

const OutputTrack: React.FC<OutputTrackProps> = ({
  typeName = "Vocals",
  zoom = 1.0,
  index = 0,
  audioBuffer,
  selected,
  name,
  blob,
  color,
  originalFileName,
}) => {
  const outputTypeIconPath =
    `/assets/septool/output-types/Icon_` + typeName + ".svg";

  const handleDownload = () => {
    let originalTitle = originalFileName;
    if (originalFileName && originalFileName.endsWith(".wav"))
      originalTitle = originalFileName.slice(0, -4);
    else if (originalFileName && originalFileName.endsWith(".mp3"))
      originalTitle = originalFileName.slice(0, -4);
    saveAs(blob, originalTitle ? `${originalTitle}_${name}` : `${name}.wav`);
  };

  useEffect(() => {
    const mainOutputsInputOverlay = document.getElementById(
      "main-outputs-input-overlay"
    );
    const waveformContainer = document.getElementById(
      "output-track-right-side-" + index
    );
    if (mainOutputsInputOverlay && waveformContainer) {
      mainOutputsInputOverlay.addEventListener("scroll", () => {
        waveformContainer.scrollLeft = mainOutputsInputOverlay.scrollLeft;
      });
    }

    return () => {
      if (mainOutputsInputOverlay && waveformContainer) {
        mainOutputsInputOverlay.removeEventListener("scroll", () => {
          waveformContainer.scrollLeft = mainOutputsInputOverlay.scrollLeft;
        });
      }
    };
  }, [index]);

  // Audio Volume
  const {
    players,
    soloPlayer,
    unSoloPlayer,
    mutePlayer,
    changePlayerVolume,
    globalMute,
    soloIndex,
    setSoloIndex,
  } = React.useContext(MultiPlayerContext) as IMultiPlayerContext;
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const volumeBar = useRef<HTMLInputElement>(null);
  const [isSolo, setIsSolo] = useState<boolean>(false);
  const [muteFromSolo, setMuteFromSolo] = useState<boolean>(false);

  useEffect(() => {
    if (soloIndex === -1000) {
      setMuteFromSolo(false);
      return;
    }
    if (soloIndex === index) {
      setMuteFromSolo(false);
    } else {
      setMuteFromSolo(true);
    }
  }, [muteFromSolo, index, soloIndex]);

  // Solo Player
  useEffect(() => {
    if (globalMute) {
      setIsSolo(false);
      setSoloIndex(-1000);
      return;
    }
  }, [isSolo, index, soloPlayer, unSoloPlayer, globalMute, setSoloIndex]);

  useEffect(() => {
    if (globalMute) {
      setIsMuted(globalMute);
      mutePlayer(index, globalMute);
    } else {
      setIsMuted(false);
      mutePlayer(index, false);
    }
  }, [globalMute, index, mutePlayer]);

  const changeVolume = () => {
    if (players.length === 0) return;
    const muteState = players[index].mute;
    if (muteState) mutePlayer(index, muteState);
    changePlayerVolume(index, volumeBar.current!.valueAsNumber);
  };

  useKeyPress(
    ["m"],
    () => {
      if (selected) {
        setIsMuted((state) => {
          mutePlayer(index, !state);
          return !state;
        });
      }
    },
    [77]
  );

  useKeyPress(
    ["s"],
    () => {
      if (selected) {
        setIsSolo((state) => {
          if (!state) {
            soloPlayer(index);
            setSoloIndex(index);
          } else {
            unSoloPlayer(index);
            setSoloIndex(-1000);
          }
          return !state;
        });
      }
    },
    [83]
  );

  return (
    <div className="sep-tool-output-track-border-container">
      <div
        className="sep-tool-output-track-main-container"
        style={{ backgroundColor: selected ? "#3F4043" : "" }}
      >
        <div className="sep-tool-output-track-type">
          <SimpleSVGComponent
            icon={outputTypeIconPath}
            alt={"icon-" + typeName}
            width="4.5vw"
            height="4.5vw"
          />
        </div>
        <div className="sep-tool-output-track-content">
          <div className="sep-tool-output-track-left-side">
            <div className="sep-tool-output-track-buttons">
              <div
                className="bassnet-audio-track-square-button"
                onClick={() => {
                  setIsMuted((state) => {
                    mutePlayer(index, !state);
                    return !state;
                  });
                }}
                style={{
                  backgroundColor: isMuted ? theme.palette.lightGrey : "",
                  color: isMuted ? theme.palette.lowIntenseOrange : "",
                  zIndex: isMuted ? 1 : 0,
                }}
              >
                M
              </div>
              <div
                className="bassnet-audio-track-square-button"
                onClick={() => {
                  setIsSolo((state) => {
                    if (!state) {
                      soloPlayer(index);
                      setSoloIndex(index);
                    } else {
                      unSoloPlayer(index);
                      setSoloIndex(-1000);
                    }
                    return !state;
                  });
                }}
                style={{
                  backgroundColor: isSolo ? theme.palette.lightGrey : "",
                  color: isSolo ? theme.palette.navyBlue : "",
                }}
              >
                S
              </div>
              |
              <div
                className="source-sep-download-icon"
                onClick={handleDownload}
                style={{
                  backgroundColor: theme.palette.lightGrey,
                  borderRadius: "50%",
                  width: "1.7vw",
                  height: "1.7vw",
                  boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <SimpleSVGComponent
                  icon={downloadIcon}
                  alt="download-icon"
                  cursor={true}
                  height="1vw"
                  width="1vw"
                  style={{ marginLeft: "0.07vw" }}
                />
              </div>
            </div>
            <div className="source-sep-audio-track-volume-input-container">
              <div
                className="source-sep-audio-track-volume-button-container"
                onClick={() => {
                  setIsMuted((state) => {
                    mutePlayer(index, !state);
                    return !state;
                  });
                }}
              >
                {isMuted ? (
                  <VolumeMute style={{ height: "1rem", width: "1rem" }} />
                ) : (
                  <VolumeUpIcon style={{ height: "1rem", width: "1rem" }} />
                )}
              </div>
              <input
                type="range"
                className="audio-progress-bar"
                ref={volumeBar}
                min={-100}
                max={0}
                defaultValue={-15}
                step={1}
                onChange={changeVolume}
              />
            </div>
          </div>
          <div
            className="sep-tool-output-track-right-side"
            id={"output-track-right-side-" + index}
          >
            {audioBuffer && (
              <WaveformContainer
                audioBuffer={audioBuffer}
                color={color ?? theme.palette.navyBlue}
                isUsingTone={true}
                onChangeSliderValue={(value: number) => {
                  Tone.Transport.seconds = (value * audioBuffer.duration) / 100;
                }}
                zoom={zoom}
                index={index}
              />
            )}
          </div>
        </div>
      </div>
      {(muteFromSolo || isMuted) && (
        <div className="source-sep-mute-audio-track-container">
          <div className="source-sep-no-audio-tracks-text">Muted audio</div>
        </div>
      )}
    </div>
  );
};

export default OutputTrack;
