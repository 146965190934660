const themeConfig = {
  palette: {
    darkOpacityGrey: "#424242",
    darkGrey: "#28272c",
    lightGrey: "#3F4043",
    black: "#1A191E",
    blue: "#3B3650",
    green: "#496854",
    red: "#FF0705",
    darkRed: "#A80000",
    white: "#FFFFFF",
    purple: "#5A46D2",
    grayPurple: "#3B3650",
    darkPurple: "#45369F",
    shinyPurple: "#7762CB",
    shinyPurpleLowOpacity: "rgba(119, 98, 203, 0.2)",
    darkYellow: "#DB9C3B",
    darkYellowLowOpacity: "rgba(219, 156, 59, 0.2)",
    lightPink: "rgba(255, 99, 132, 0.8)",
    lightPinkLowOpacity: "rgba(255, 99, 132, 0.2)",
    darkPink: "#722940",
    darkPinkLowOpacity: "rgba(212, 95, 185, 0.2)",
    orange: "#FF9473",
    orangeLowOpacity: "rgba(255, 148, 115, 0.2)",
    lightYellow: "#EDD039",
    lightYellowLowOpacity: "rgba(237, 208, 57, 0.2)",
    navyBlue: "#197FD2",
    navyBlueLowOpacity: "rgba(25, 127, 210, 0.2)",
    lightGreen: "#008F74",
    lightGreenLowOpacity: "rgba(0, 143, 116, 0.2)",
    /// New colors
    orangeBrown: "#CA5022",
    orangeBrownLowOpacity: "rgba(202, 80, 34, 0.2)",
    pink: "#CA4872",
    pinkLowOpacity: "rgba(202, 72, 114, 0.2)",
    salmon: "#CF8977",
    salmonLowOpacity: "rgba(207, 137, 119, 0.2)",
    darkSalmon: "#B96365",
    lowIntenseOrange: "#F5A623",
    lowIntenseOrangeLowOpacity: "rgba(245, 166, 35, 0.2)",
    yellow: "#D3A958",
    yellowLowOpacity: "rgba(211, 169, 88, 0.2)",
    lightBlue: "#85A4D6",
    darkBlue: "#3A4E7A",
    specialBlack: "#1f1e22",
  },

  spacingUnit: 8,
};

/* Do not modify here, edit themeConfig instead */
export const theme = {
  palette: {
    ...themeConfig.palette,
  },
  spacing: (...units: number[]) =>
    units.map((unit) => `${themeConfig.spacingUnit * unit}px`).join(" "),
};
