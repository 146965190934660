import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-main-container">
      <div className="footer-container">
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && "© Sony Computer Sciences Laboratories, Inc."}
      </div>
      <div className="sony-footer">
        {process.env.REACT_APP_SONY_TRADEMARKS === "true" && 
        <>
          <a href="https://campaign.odw.sony-europe.com/dynamic/terms-conditions/index.jsp?country=fr&language=fr" target="_blank" rel="noreferrer" >
            TERMS AND CONDITIONS OF WEBSITE USE
          </a>
          <a href="https://www.sony.fr/eu/pages/privacy/fr_FR/privacy_overview.html" target="_blank" rel="noreferrer">
            PRIVACY POLICY
          </a>
          </>
        }
      </div>
    </div>
  );
};

export default Footer;