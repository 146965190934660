import React, { createContext, useCallback, useState } from "react";
import * as Tone from "tone";

interface MultiPlayerContextProps {
  children: React.ReactNode;
}

export interface IMultiPlayerContext {
  players: Tone.Player[];
  setPlayers: React.Dispatch<React.SetStateAction<Tone.Player[]>>;
  soloForPlayer: Tone.Solo[];
  setSoloForPlayer: React.Dispatch<React.SetStateAction<Tone.Solo[]>>;
  soloPlayer: (index: number) => void;
  unSoloPlayer: (index: number) => void;
  mutePlayer: (index: number, mute: boolean) => void;
  soloIndex: number;
  setSoloIndex: React.Dispatch<React.SetStateAction<number>>;
  changePlayerVolume: (index: number, volume: number) => void;
  globalMute: boolean;
  setGlobalMute: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  cleanUpPlayers: () => void;
  currentTime: number;
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
}

export const MultiPlayerContext = createContext<
  IMultiPlayerContext | undefined
>(undefined);

export const MultiPlayerContextProvider = ({
  children,
}: MultiPlayerContextProps) => {
  const [players, setPlayers] = useState<Tone.Player[]>([]);
  const [soloForPlayer, setSoloForPlayer] = useState<Tone.Solo[]>([]);
  const [playersMuteState, setPlayersMuteState] = useState<boolean[]>([]);
  const [soloIndex, setSoloIndex] = useState<number>(-1000);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const [globalMute, setGlobalMute] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const cleanUpPlayers = useCallback(() => {
    // clean up all players, panners and transport and soloForPlayer
    setIsPlaying(false);
    players.forEach((player) => {
      if (player.state === "started") {
        player.stop();
      }
      player.disconnect();
      player.dispose();
    });
    soloForPlayer.forEach((solo) => {
      solo.disconnect();
      solo.dispose();
    });
    Tone.Transport.stop();
    Tone.Transport.seconds = 0;
    setPlayers([]);
    setSoloForPlayer([]);
    setSoloIndex(-1000);
    setPlayersMuteState([]);
  }, [players, setPlayers, soloForPlayer]);

  const soloPlayer = useCallback(
    (index: number) => {
      if (players.length === 0) return;
      const localPlayers = players;

      // save the current mute state of all players
      const currentMuteState = players.map((player) => player.mute);
      setPlayersMuteState(currentMuteState);
      localPlayers.forEach((player, i) => {
        if (index === i) {
          player.mute = false;
        } else {
          player.mute = true;
        }
      });
      soloForPlayer[index].solo = true;
      setPlayers(localPlayers);
    },
    [players, setPlayers, soloForPlayer, setPlayersMuteState]
  );

  const unSoloPlayer = useCallback(
    (index: number) => {
      if (players.length === 0) return;
      const localPlayers = players;
      localPlayers.forEach((player, index) => {
        player.mute = playersMuteState[index];
      });
      soloForPlayer[index].solo = false;
      setPlayers(localPlayers);
    },
    [players, setPlayers, soloForPlayer, playersMuteState]
  );

  const mutePlayer = useCallback(
    (index: number, mute: boolean) => {
      if (players.length === 0) return;
      const localPlayers = players;
      setPlayersMuteState((prev) => {
          const newState = [...prev];
          newState[index] = mute;
          return newState;
      });
      localPlayers[index].mute = mute;
      setPlayers(localPlayers);
    },
    [players, setPlayers]
  );

  const changePlayerVolume = (index: number, volume: number) => {
    const player = players[index];
    player.volume.value = volume;
    setPlayers(players);
  };

  const contextValue = {
    players,
    setPlayers,
    soloForPlayer,
    setSoloForPlayer,
    soloPlayer,
    unSoloPlayer,
    mutePlayer,
    soloIndex,
    setSoloIndex,
    changePlayerVolume,
    globalMute,
    setGlobalMute,
    isPlaying,
    setIsPlaying,
    cleanUpPlayers,
    currentTime,
    setCurrentTime,
  };

  return (
    <MultiPlayerContext.Provider value={contextValue}>
      {children}
    </MultiPlayerContext.Provider>
  );
};
