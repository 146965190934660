import "../../components/UI/Generic.css";
import "./HomePage.css";
import StateOfTheArtGif from "../../assets/gifs/state-of-the-art.gif";
import SimpleSVGComponent from "../../components/UI/SimpleSVGComponent";
import APIGif from "../../assets/gifs/api.gif";
import UnmixAudioGif from "../../assets/gifs/unmix-audio.gif";
import { GenericButton } from "../../components/UI/GenericButton";
import { useNavigate } from "react-router-dom";
import React from "react";
import { UserContext, UserContextProps } from "../../contexts/UserContext";

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

export const HomePage = () => {
  const { userToken } = React.useContext(UserContext) as UserContextProps;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (userToken) {
      navigate("/dashboard");
    }
  }, [userToken, navigate]);

  interface DescriptionProps {
    alt: string;
    icon: string;
    height?: string | undefined;
    title: string;
    description: string;
    width?: string;
    padding?: string;
  }

  const DescriptionElement = (props: DescriptionProps) => {
    return (
      <div className="description-container" style={{ padding: props.padding }}>
        <SimpleSVGComponent
          alt={props.alt}
          icon={props.icon}
          height={props.height}
          width={props.width}
        />
        <div className="description-title">{props.title}</div>
        <div className="description-description">{props.description}</div>
      </div>
    );
  };

  return (
    <div className="sourece-sep-home-page-container">
      <div className="source-sep-home-page">
        <div className="home-page-subtitle">
          AI Sound Separation
          {process.env.REACT_APP_SONY_TRADEMARKS === "true" && " by Sony"}
        </div>
        <div className="home-page-title">
          Separate & enhance mixed sound sources using AI
        </div>
        <div className="home-page-description-container">
          <DescriptionElement
            alt="state-of-the-art-icon"
            description={"AI Sound Separation algorithms" + (process.env.REACT_APP_SONY_TRADEMARKS === "true" ? " developed by Sony." : "")}
            icon={StateOfTheArtGif}
            title="State-of-the-art algorithms"
            height="6vh"
            width="auto"
            padding="1.5% 1.5% 2.5% 1.5%"
          />
          <DescriptionElement
            alt="unmix-audio-icon"
            description="Separate mixed audio sources into component tracks, such as: vocals, drums, bass and other instruments."
            icon={UnmixAudioGif}
            title="Unmix audio into separate tracks"
            height="6vh"
            width="auto"
            padding="1.5% 1.5% 2.5% 1.5%"
          />
          <DescriptionElement
            alt="api-icon"
            description="Integrate AI Sound Separation easily into your application using our web API."
            icon={APIGif}
            title="Demo and web APIs"
            height="8vh"
            width="auto"
            padding="0 1.5% 2.5% 1.5%"
          />
        </div>
        <div className="home-page-button-container">
          <GenericButton
            arrowIcon
            text={mvpMode ? "Get started" : "Create an account"}
            onClick={() => navigate("/register")}
            padding="3% 7%"
            width="50%"
            style={{
              cursor: "pointer",
            }}
            hoverPadding="3% 2%"
          />
          {!mvpMode && (
            <div className="light-text" onClick={() => navigate("/login")}>
              Already an account ?
              <span className="underline underline-offset-2 cursor-pointer">
                Log in
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
