import React from "react";
import { ContextProviderProps } from "./InputsContext";
import { Form } from "react-router-dom";

export interface UserContextProps {
  fetchActivationCode: (code: string) => Promise<any>;
  userToken: string | undefined;
  setUserToken: React.Dispatch<React.SetStateAction<undefined | string>>;
  userEmail: string | undefined;
  setUserEmail: React.Dispatch<React.SetStateAction<undefined | string>>;
  invitationCode: string | undefined;
  setInvitationCode: React.Dispatch<React.SetStateAction<undefined | string>>;
  userId: undefined | string;
  setUserId: React.Dispatch<React.SetStateAction<undefined | string>>;
  credits: number;
  setCredits: React.Dispatch<React.SetStateAction<number>>;
}

export const UserContext = React.createContext<null | UserContextProps>(null);

export interface GenericFetchProps {
  url: string;
  method: string;
  headers?: HeadersInit;
  body?: any;
}

export const genericFetch = async (props: GenericFetchProps) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}${props.url}`, {
      body: JSON.stringify(props.body),
      method: props.method,
      headers: {
        "Content-Type": "application/json",
        ...props.headers,
      },
    });
    return res.json();
  } catch (e) {
    console.log("Error during fetch: ", e);
    return e;
  }
};

export const UserContextProvider = ({ children }: ContextProviderProps) => {
  const [userToken, setUserToken] = React.useState<undefined | string>(
    undefined
  );
  const [userEmail, setUserEmail] = React.useState<undefined | string>(
    undefined
  );
  const [invitationCode, setInvitationCode] = React.useState<
    undefined | string
  >(undefined);
  const [userId, setUserId] = React.useState<undefined | string>(undefined);
  const [credits, setCredits] = React.useState<number>(0);

  const fetchActivationCode = async (code: string) => {
    const body = new FormData();
    body.append("code", code);
    const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/check-activation-code`, {
      body: body,
      method: "POST",
    });
    return res.json();
  };

  const contextValue = {
    fetchActivationCode,
    userToken,
    setUserToken,
    userEmail,
    setUserEmail,
    invitationCode,
    setInvitationCode,
    userId,
    setUserId,
    credits,
    setCredits,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};
