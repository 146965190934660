import React from "react";
import "./ProfilPage.css";
import ProfilPicture from "../../assets/icons/profil-picture.svg";
import MailIcon from "../../assets/icons/mail-icon.svg";
import ImportantIcon from "../../assets/icons/important-icon.svg";
import PencilIcon from "../../assets/icons/pencil-icon.svg";
import LockIcon from "../../assets/icons/lock-icon.svg";
import { GenericButton } from "../../components/UI/GenericButton";

const ProfilPage: React.FC = () => {
  return (
    <div className="profil-page-container">
      <div className="profil-page-header">
        <GenericButton
          width="7%"
          withoutHover
          hoverPadding="0"
          padding="0"
          text="Your account"
        />
      </div>
      <div className="profil-page-main-container">
        <div className="generic-xl-bold-text" style={{ marginBottom: "3%" }}>
          Your profile
        </div>
        <div className="profil-page-infos-container">
          <div className="profil-page-info">
            <div className="profil-page-picture-container">
              <img src={ProfilPicture} alt="profil-pic" />
            </div>
            <GenericButton
              height="70%"
              text="Yuki Mitsufuji"
              color="#3F4043"
              textClassName="generic-medium-bold-text"
              hoverColor="#3F4043"
              padding="2% 4%"
              hoverPadding="2% 4%"
              endIcon={PencilIcon}
              iconHeight="auto"
              iconWidth="7%"
              justifyContent="space-between"
            />
          </div>
          <div className="profil-page-info">
            <div className="profil-page-title-container">
              <img src={MailIcon} alt="mail-icon" />
              <div className="generic-small-text">Email</div>
            </div>
            <GenericButton
              style={{ justifyContent: "left" }}
              height="70%"
              text="yuki.mitsufuji@sony.com"
              color="#3F4043"
              hoverColor="#3F4043"
              textClassName="generic-medium-bold-text"
              padding="2% 4%"
              withoutHover
              hoverPadding="2% 4%"
            />
          </div>
          <div className="profil-page-info">
            <div className="profil-page-title-container">
              <img src={LockIcon} alt="password-icon" />
              <div className="generic-small-text">Password</div>
            </div>
            <div className="profil-page-password">
              <GenericButton
                textClassName="generic-medium-bold-text"
                style={{ justifyContent: "left" }}
                padding="2% 4%"
                height="70%"
                text="*******"
                color="#3F4043"
                hoverColor="#3F4043"
                withoutHover
                hoverPadding="2% 4%"
              />
              <div className="generic-small-text" style={{ cursor: "pointer" }}>
                Change password
              </div>
            </div>
          </div>
        </div>
        <div className="underline" style={{ backgroundColor: "white" }}></div>
        <div className="profil-page-notif-container">
          <div
            className="profil-page-title-container"
            style={{ height: "25%" }}
          >
            <div
              className="generic-button-container"
              style={{ height: "60%", width: "17%" }}
            >
              <img src={ImportantIcon} alt="important-icon" />
            </div>
            <div className="generic-small-text">Notification</div>
          </div>
          <div className="profil-page-notif-mail">
            <div className="generic-small-text">
              Send an e-mail when a track separation is complete
            </div>
            <input type="checkbox" defaultChecked className="checkbox" />
          </div>
          <div className="profil-page-notif-download">
            <div className="generic-small-text">
              Automatically download stems as
            </div>
            <GenericButton
              width="10%"
              text=".MP3"
              textClassName="generic-small-bold-text"
              color="#3F4043"
              hoverColor="#1A191E"
            />
            <GenericButton
              width="10%"
              text=".WAV"
              textClassName="generic-small-bold-text"
              color="#3F4043"
              hoverColor="#1A191E"
            />
          </div>
        </div>
        <div className="profil-page-footer">
          <GenericButton
            height="65%"
            text="Save profile"
            color="#3F4043"
            hoverColor="#1A191E"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfilPage;
