import React from "react";
import "./LibraryRightComponent.css";
import { IconTitle } from "../Library";
import ArtistIcon from "../../../assets/icons/person-icon.svg";
import FormatIcon from "../../../assets/icons/format-icon.svg";
import DateIcon from "../../../assets/icons/date-icon.svg";
import DurationIcon from "../../../assets/icons/duration-icon.svg";
import SeparationModelIcon from "../../../assets/icons/separation-model-icon.svg";
import SplitIcon from "../../../assets/icons/split-icon.svg";
import DownloadIcon from "../../../assets/icons/download-icon.svg";
import DropdownArrow from "../../../assets/icons/dropdown-arrow-icon.svg";
import MusicIcon from "../../../assets/icons/music-icon.svg";
import { GenericButton } from "../../../components/UI/GenericButton";
import LibraryFooter from "./LibraryFooter/LibraryFooter";
import SongInfos from "./SongInfos/SongInfos";
import SourceSepGif from "../../../assets/gifs/source-sep.gif";
import {
  IDirectory,
  ISong,
  InputsContext,
  InputsContextProps,
} from "../../../contexts/InputsContext";
import { useKeyPress } from "../../../hooks/UseKeyPress";

export const LibraryRightComponent: React.FC = () => {
  const { inputs, isSong, isDirectory, currentSong, setCurrentSong } =
    React.useContext(InputsContext) as InputsContextProps;
  const [openModal, setOpenModal] = React.useState(false);
  const [currentInputs, setCurrentInputs] = React.useState<ISong[]>([]);
  const [isGlobalDetailOpen, setIsGlobalDetailOpen] = React.useState(false);

  React.useEffect(() => {
    if (currentInputs.length > 0 && currentSong === undefined) {
      setCurrentSong(currentInputs[0]);
    }
  }, [currentInputs, currentSong, setCurrentSong]);

  useKeyPress(
    ["ArrowLeft"],
    () => {
      if (currentInputs.length > 0) {
        setCurrentSong(
          currentInputs[
            currentInputs.findIndex((song) => song === currentSong) !== 0
              ? currentInputs.findIndex((song) => song === currentSong) - 1
              : currentInputs.length - 1
          ]
        );
      }
    },
    [37]
  );

  useKeyPress(
    ["ArrowRight"],
    () => {
      if (currentInputs.length > 0) {
        setCurrentSong(
          currentInputs[
            (currentInputs.findIndex((song) => song === currentSong) + 1) %
              currentInputs.length
          ]
        );
      }
    },
    [39]
  );

  React.useEffect(() => {
    if (inputs.length > 0) {
      const temp: ISong[] = [];
      inputs.forEach((input) => {
        if (isSong(input) && (input as ISong).added) temp.push(input as ISong);
        else if (isDirectory(input)) {
          temp.push(
            ...(input as IDirectory).songs.filter((song) => song.added)
          );
        }
      });
      setCurrentInputs(temp as ISong[]);
    }
  }, [inputs, isSong, isDirectory]);

  const ColumnInfo = [
    {
      title: "Title",
      icon: MusicIcon,
      width: "16%",
      gap: "5%",
      alt: "title-icon",
    },
    {
      title: "Artist",
      icon: ArtistIcon,
      width: "16%",
      gap: "5%",
      alt: "artist-icon",
    },
    {
      title: "Format",
      icon: FormatIcon,
      width: "9%",
      gap: "10%",
      alt: "format-icon",
    },
    {
      title: "Date",
      icon: DateIcon,
      width: "9%",
      gap: "10%",
      alt: "date-icon",
    },
    {
      title: "Duration",
      icon: DurationIcon,
      width: "9%",
      gap: "8%",
      alt: "duration-icon",
    },
    {
      title: "Separation model",
      icon: SeparationModelIcon,
      width: "14%",
      gap: "5%",
      alt: "sep-model-icon",
    },
    {
      title: "Split stems",
      icon: SplitIcon,
      width: "10%",
      gap: "5%",
      alt: "split-icon",
    },
    {
      title: "Download all",
      icon: DownloadIcon,
      width: "12%",
      gap: "5%",
      alt: "download-icon",
    },
  ];

  return (
    <div className="source-sep-library-right-container">
      <div className="source-sep-library-right-header">
        <GenericButton
          withoutHover
          text="Your track"
          width="8%"
          hoverPadding="0"
          padding="0"
        />
      </div>
      <div className="source-sep-library-right-columns-container">
        <div className="source-sep-library-right-columns">
          {ColumnInfo.map((info, index) => (
            <IconTitle
              key={index}
              alt={info.alt}
              icon={info.icon}
              title={info.title}
              gap={info.gap}
              width={info.width}
              height="100%"
              padding="0.5% 1%"
            />
          ))}
          <div className="source-sep-library-column-arrow-dropdown" onClick={() => setIsGlobalDetailOpen(!isGlobalDetailOpen)}>
            <img
              src={DropdownArrow}
              alt="arrow-down"
              style={{ width: "100%", height: "auto", transform: (isGlobalDetailOpen ? "" : "rotate(180deg)") }}
            />
          </div>
        </div>
        {currentInputs.length > 0 && (
          <div className="source-sep-library-right-song-infos">
            {currentInputs.map((input, index) => (
              <SongInfos
                key={index}
                input={input}
                setOpenModal={setOpenModal}
                openModal={openModal}
                globalDetail={isGlobalDetailOpen}
              />
            ))}
          </div>
        )}
        {currentInputs.length === 0 && (
          <div className="source-sep-library-right-main">
            <img
              src={SourceSepGif}
              alt="source-sep-gif"
              style={{ height: "8vh" }}
            />
            <div className="source-sep-library-right-no-track generic-small-text">
              No track selected yet...
            </div>
          </div>
        )}
      </div>
      <LibraryFooter openModal={openModal} />
    </div>
  );
};
