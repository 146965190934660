import io from 'socket.io-client';

const socket = io(`${process.env.REACT_APP_SERVER_URL}/feed`);

socket.on('connect', ( ) => {
  console.log('Connected to server!');
});

socket.on('message', (data) => {
  console.log(data);
});

socket.on('progress', (data) => {
  console.log("received sampling_progress:", data);
})

socket.on("task_ended", (data) => {
  console.log("ici task ended", data)
})

socket.on("task_started", (data) => {
  console.log("received task started", data)
})

export default socket;