import React from "react";
import { ContextProviderProps } from "./InputsContext";
import { genericFetch } from "./UserContext";

export interface ISepModel {
  title: string;
  contentDetails: JSX.Element | undefined;
}

export interface SepModelsContextProps {
  getModelIndex: (model: string | undefined) => number;
  sepModels: ISepModel[];
  setSepModels: React.Dispatch<React.SetStateAction<ISepModel[]>>;
  currentSelectedSepModel: ISepModel | undefined;
  setCurrentSelectedSepModel: React.Dispatch<
    React.SetStateAction<ISepModel | undefined>
  >;
  currentSelectedSepModelIsGenerated: boolean;
  setCurrentSelectedSepModelIsGenerated: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export const SepModelsContext =
  React.createContext<null | SepModelsContextProps>(null);

export const SepModelsContextProvider = ({
  children,
}: ContextProviderProps) => {
  const [sepModels, setSepModels] = React.useState<ISepModel[]>([]);
  const [currentSelectedSepModel, setCurrentSelectedSepModel] =
    React.useState<ISepModel>();
  const [
    currentSelectedSepModelIsGenerated,
    setCurrentSelectedSepModelIsGenerated,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      if (sepModels.length === 0) {
        const res = await genericFetch({
          url: "/separation-models",
          method: "GET",
        });
        if (!res.error) {
          setSepModelsToContext(res);
        }
      }
    };
    fetchData();
  }, [sepModels]);

  const getModelIndex = (model: string | undefined) => {
    if (!model) return -1;
    return sepModels.findIndex((elem: ISepModel) => elem.title === model);
  };

  const setSepModelsToContext = (res: string[]) => {
    res.forEach((model) => {
      setSepModels((prev) => {
        return [...prev, { title: model, contentDetails: <> High Quality </> }];
      });
    });
  };

  const ContextValue = {
    sepModels,
    setSepModels,
    getModelIndex,
    currentSelectedSepModel,
    setCurrentSelectedSepModel,
    currentSelectedSepModelIsGenerated,
    setCurrentSelectedSepModelIsGenerated,
  };

  return (
    <SepModelsContext.Provider value={ContextValue}>
      {children}
    </SepModelsContext.Provider>
  );
};
