import { CSSProperties, FC, useState } from "react";
import "./SepToolAudioUpload.css";
import { Alert, Snackbar } from "@mui/material";
import { useDropzone } from "react-dropzone";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import stateOfTheArtIcon from "../../assets/gifs/state-of-the-art.gif";
import unmixAudioIcon from "../../assets/gifs/unmix-audio.gif";
import React from "react";

interface SepToolAudioUploadProps {
  setDroppedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  droppedFiles?: File[];
  maxFile?: number;
  acceptOnlyAudioFiles?: boolean;
  style?: CSSProperties;
  className?: string;
  height?: string;
  width?: string;
}

const SepToolAudioUpload: FC<SepToolAudioUploadProps> = (props) => {
  const [isErrorUploadingFile, setIsErrorUploadingFile] =
    useState<boolean>(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] =
    useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);


  const onDropFile = (files: any, filesRejection: any, event: any) => {
    setIsLoadingUploadingFile(true);
    if (!files || files.length === 0) {
      setIsErrorUploadingFile(true);
      setIsLoadingUploadingFile(false);
      return;
    }

    if (props.droppedFiles && props.setDroppedFiles) {
      let newFiles = [...props.droppedFiles, ...files];
      props.setDroppedFiles(newFiles);
    }
    setIsUploadSuccess(true);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: props.acceptOnlyAudioFiles
        ? {
            "audio/wav": [".wav", ".flac"],
            "audio/raw": [".wav", ".flac"],
            "audio/mp3": [".mp3"],
          }
        : {},
      onDrop: onDropFile,
      maxFiles: props.maxFile ?? undefined,
      noKeyboard: true,
    });

  return (
    <div className="sep-tool-main-drag-and-drop-container-border-container">
      <div className="sep-tool-drag-and-drop-container-main-container">
        <Snackbar
          open={isErrorUploadingFile}
          onClose={() => setIsErrorUploadingFile(false)}
        >
          <Alert
            onClose={() => setIsErrorUploadingFile(false)}
            severity="error"
          >
            Error while uploading. Please try again later.
          </Alert>
        </Snackbar>
        <Snackbar
          open={isUploadSuccess}
          onClose={() => setIsUploadSuccess(false)}
        >
          <Alert onClose={() => setIsUploadSuccess(false)} severity="success">
            Files successfully uploaded!
          </Alert>
        </Snackbar>
        <div {...getRootProps({ className: "drag-and-drop-container" })}>
          <input {...getInputProps()} />
          {isLoadingUploadingFile ? (
            <div className="sep-tool-upload-in-progress-content">
              <SimpleSVGComponent
                icon={unmixAudioIcon}
                alt="unmix-audio-icon"
                cursor={false}
                height="3.5vw"
                width="3.5vw"
              />
              <span className="generic-big-bold-text">Uploading song...</span>
            </div>
          ) : (
            <>
              {isDragActive ? (
                isDragAccept ? (
                  <span className="generic-big-bold-text">Dragging...</span>
                ) : (
                  <span className="generic-big-bold-text">
                    Can't drag this kind of file
                  </span>
                )
              ) : (
                <div className="sep-tool-upload-audio-dd-content">
                  <SimpleSVGComponent
                    icon={stateOfTheArtIcon}
                    alt="state-of-the-art-icon"
                    cursor={false}
                    height="3.5vw"
                    width="3.5vw"
                  />
                  <span
                    className="generic-big-bold-text"
                    style={{ lineHeight: "1.5vw" }}
                  >
                    Drag & Drop a song
                    <br />
                    or a folder to separate your track(s)
                  </span>
                  <span
                    className="generic-small-text"
                    style={{ textDecoration: "underline" }}
                  >
                    or browse file
                  </span>
                  <span
                    className="generic-small-text"
                    style={{ fontStyle: "italic", marginTop: "0.5vw" }}
                  >
                    (Only .wav & .mp3 are supported)
                  </span>
                </div>
              )}
            </>
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default SepToolAudioUpload;
