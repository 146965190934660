import React, { useCallback, useEffect } from "react";
import SplitIcon from "../../assets/icons/split-separate-icon.png";
import "../UI/Generic.css";
import socket from "../../services/socketService";
import "./SplitButton.css";
import {
  ISong,
  InputsContext,
  InputsContextProps,
} from "../../contexts/InputsContext";

export interface SplitButtonProps {
  color?: string;
  hoverColor?: string;
  width?: string;
  height?: string;
  gap?: string;
  shadow?: boolean;
  withAnim?: boolean;
  input?: ISong;
  onClick?: () => void;
}

export const SplitButton: React.FC<SplitButtonProps> = (
  props: SplitButtonProps
) => {
  const [progress, setProgress] = React.useState<number>(0);
  const [isHover, setIsHover] = React.useState(false);
  const { fetchUpload } = React.useContext(InputsContext) as InputsContextProps;
  const [isInProgress, setIsInProgress] = React.useState<boolean>(false);

  const handleProgress = useCallback((data: string) => {
    const parsedData = typeof data === "string" ? JSON.parse(data) : data;
    if (parsedData.ending && parsedData.ending === true) {
      setProgress(0);
    }

    if (parsedData.progress) {
      setProgress(parsedData.progress * 100);
    }
  }, []);

  useEffect(() => {
    socket.on("progress", handleProgress);
  }, [handleProgress]);

/*   React.useEffect(() => {
    const fetchAfterProgress = async () => {
      if (progress >= 100 && props.input) {
        await fetchUpload(props.input);
      }
    };
    fetchAfterProgress();
  }, [progress, fetchUpload, props.input]); */

/*   React.useEffect(() => {
    let interval: NodeJS.Timer;
    if (isInProgress) {
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) {
            return prev + 100 / 3;
          } else {
            setIsInProgress(false);
            return 0;
          }
        });
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isInProgress]); */

  const handleClick = async () => {
    if (props.input) {
      if (props.withAnim && !isInProgress) {
        setIsInProgress(true);
        await fetchUpload(props.input);
        setIsInProgress(false);
      };
    }
  };

  return (
    <div
      className={"split-button-container " + (props.shadow ? "shadow" : "")}
      style={{
        gap: props.gap,
        backgroundColor:
          isInProgress && props.withAnim
            ? "#2A2930"
            : isHover
            ? props.hoverColor
            : props.color,
        width: props.width,
        justifyContent:
          isHover && (!props.withAnim || (props.withAnim && !isInProgress))
            ? "space-between"
            : "",
        height: props.height,
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={props.onClick ?? handleClick}
    >
      {isInProgress && props.withAnim && (
        <div
          className="split-button-progress"
          style={{ width: `${progress}%` }}
        ></div>
      )}
      <img style={{ zIndex: 3 }} alt="split-left-icon" src={SplitIcon} />
      <div className="generic-medium-bold-text" style={{ zIndex: 3 }}>
        {props.withAnim && isInProgress && progress === 100 ? "Done !" : props.withAnim && isInProgress ? "Splitting..." : "Split"}
      </div>
      <img
        alt="split-left-icon"
        src={SplitIcon}
        style={{ transform: "rotate(180deg)", zIndex: 3 }}
      />
    </div>
  );
};
