import React, { useEffect, useState, useRef } from "react";
import "./OutputsList.css";
import OutputTrack from "../OutputTrack/OutputTrack";
import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import timeLineDraw from "../../assets/septool/timeline.svg";
import Timeline from "../Timeline/Timeline";
import TimlineCursor from "../../assets/septool/timeline-cursor.svg";
import {
  InputsContext,
  InputsContextProps,
} from "../../contexts/InputsContext";
import * as Tone from "tone";
import {
  MultiPlayerContext,
  IMultiPlayerContext,
} from "../../contexts/MultiPlayerContext";
import { useKeyPress } from "../../hooks/UseKeyPress";

interface OutputsListProps {
  zoom?: number;
}

const OutputsList: React.FC<OutputsListProps> = ({ zoom = 1.0 }) => {
  // declare a state variable for the list of outputs
  const [value, setValue] = useState<number>(0);
  const color = "transparent";
  const { currentOutputTracks, currentSong } = React.useContext(
    InputsContext
  ) as InputsContextProps;
  const ProgressCursorBarRef = useRef<HTMLInputElement>(null);
  const audioBuffer = currentSong?.audioBuffer;
  const [audioDuration, setAudioDuration] = useState<number>(0);
  const [selected, setSelected] = React.useState<undefined | number>(undefined);
  const { globalMute, setGlobalMute } = React.useContext(
    MultiPlayerContext
  ) as IMultiPlayerContext;

  const onChangeSlider = (value: number) => {
    setValue(value);
    if (currentSong && audioBuffer)
      Tone.Transport.seconds = (value * audioBuffer.duration) / 100;
  };

  useEffect(() => {
    if (audioBuffer) {
      setAudioDuration(audioBuffer.duration);
    }
  }, [audioBuffer]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    if (audioBuffer === undefined) return;
    const duration = audioDuration;
    intervalId = setInterval(() => {
      if (ProgressCursorBarRef.current) {
        const currentTime = (Tone.Transport.seconds / duration) * 100;
        if (currentTime > 99) {
          setValue(100);
        } else setValue(currentTime);
      }
    }, 16);
    return () => {
      clearInterval(intervalId);
    };
  }, [audioDuration, audioBuffer]);

  useKeyPress(
    ["ArrowUp"],
    () => {
      if (selected !== undefined && currentOutputTracks?.tracks) {
        setSelected(
          selected !== 0 ? selected - 1 : currentOutputTracks.tracks.length - 1
        );
      } else if (selected === undefined && currentOutputTracks?.tracks)
        setSelected(0);
    },
    [38]
  );

  useKeyPress(
    ["ArrowDown"],
    () => {
      if (selected !== undefined && currentOutputTracks?.tracks) {
        setSelected((selected + 1) % currentOutputTracks.tracks.length);
      } else if (selected === undefined && currentOutputTracks?.tracks)
        setSelected(0);
    },
    [38]
  );

  useKeyPress(
    ["m"],
    () => {
      if (selected === undefined) {
        setGlobalMute(!globalMute);
      }
    },
    [77]
  );

  return (
    <div className="sep-tool-outputs-list-border-container">
      <div
        id="source-sep-outputs-list"
        className="sep-tool-outputs-list-main-container"
      >
        {currentOutputTracks?.tracks?.map((track, index) => (
          <OutputTrack
            key={index}
            zoom={zoom}
            typeName={track.type}
            color={track.color}
            index={index}
            audioBuffer={track.audioBuffer}
            selected={selected === index}
            name={track.name}
            blob={track.blob}
            originalFileName={currentSong?.title}
          />
        ))}
      </div>
      <div
        className="sep-list-outputs-input-overlay"
        id="main-outputs-input-overlay"
      >
        <div
          className="progress-cursor-input-bar-container"
          style={{ width: zoom === 1 ? "100%" : `${100 * zoom}%` }}
        >
          <div className="sep-tool-progress-timeline">
            <SimpleSVGComponent
              icon={timeLineDraw}
              alt="timeline"
              width="100%"
              height="1vh"
            />
          </div>
          <div className="sep-tool-progress-timestamps-container">
            <Timeline
              duration={currentSong?.audioBuffer.duration ?? 0}
              zoom={zoom}
            />
          </div>
          <div
            className="progress-timeline-cursor-container"
            style={{ left: `${value}%` }}
          >
            <SimpleSVGComponent
              icon={TimlineCursor}
              alt="timeline cursor"
              width="1vw"
              height="1vw"
            />
          </div>
          <input
            ref={ProgressCursorBarRef}
            type="range"
            value={isNaN(value) ? 0 : value}
            className="progress-cursor-input-bar"
            onChange={(e) => onChangeSlider(Number(e.target.value))}
            style={{
              backgroundImage: `linear-gradient(${color}, ${color})`,
              padding: "0px",
            }}
            step="any"
          />
        </div>
      </div>
    </div>
  );
};

export default OutputsList;
