import React, { useState } from "react";
import "./RegisterPage.css";
import SimpleSVGComponent from "../../components/UI/SimpleSVGComponent";
import registerIcon from "../../assets/gifs/source-sep.gif";
import { GenericButton } from "../../components/UI/GenericButton";
import TextInputField from "../../components/UI/TextInputField";
import GoogleIcon from "@mui/icons-material/Google";
import "./RegisterPage.css";
import { UserContext, UserContextProps } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import CustomSnackBar from "../../components/UI/CustomSnackBar";
import { jwtDecode } from "jwt-decode";
import socket from "../../services/socketService";

interface RegisterPageProps {}

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

const RegisterPage: React.FC<RegisterPageProps> = (props) => {
  const {
    setUserToken,
    userToken,
    fetchActivationCode,
    setUserEmail,
    setInvitationCode,
    setUserId,
    credits,
    setCredits,
  } = React.useContext(UserContext) as UserContextProps;
  const navigate = useNavigate();
  const isActivationSet = false;
  const [email, setEmail] = useState("");
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (userToken) navigate("/dashboard");
  }, [userToken, navigate]);

  const handleCodeRequest = async () => {
    if ((email && code && mvpMode) || (code && !mvpMode)) {
      if (!emailIsValid(email) && mvpMode) {
        setError("Invalid email format");
        return;
      }
      const res = await fetchActivationCode(code);
      if (res.error) {
        setError(res.error);
      } else {
        const decoded = jwtDecode(res.token);
        const currentDate = new Date();
        if (decoded.exp && decoded.exp * 1000 > currentDate.getTime()) {
          if (!mvpMode) localStorage.setItem("userToken", res.token);
          setUserId(res.user_id);
          setUserEmail(email);
          setInvitationCode(code);
          setUserToken(res.token);
          setCredits(res.credits);
          socket.emit("authenticated", {"user_id": res.user_id});
        }
      }
    } else {
      setError("Invalid email or code");
    }
  };

  const emailIsValid = (email: string) => {
    return email.includes("@") && email.includes(".");
  };

  return (
    <div className="register-page-main-container">
      <div
        className="register-page-box-container"
        style={{ gap: !isActivationSet ? "2vw" : "" }}
      >
        <div className="register-page-title-content">
          <SimpleSVGComponent
            alt="register-icon"
            icon={registerIcon}
            height="4vw"
            width="4vw"
          />
          <div className="register-page-title-description">
            {!isActivationSet
              ? "Add your invitation code"
              : "Create an account"}
          </div>
          <div className="register-page-title">
            {!isActivationSet ? "Welcome" : "Sign Up"}
          </div>
        </div>
        {!isActivationSet ? (
          <form
            onSubmit={async (e: any) => {
              e.preventDefault();
            }}
            className="register-form-container"
          >
            <div className="register-form-container">
              {mvpMode && (
                <TextInputField
                  type="email"
                  height="4.3vh"
                  width="13vw"
                  padding="0.5vw 0.7vw"
                  title="Your email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  style={{ marginBottom: "1vw" }}
                  value={email}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      document.getElementById("invitation-code-input")?.focus();
                    }
                  }}
                />
              )}
              <TextInputField
                type="text"
                height="4.3vh"
                width="13vw"
                padding="0.5vw 0.7vw"
                title="Your invitation code"
                onChange={(e: any) => setCode(e.target.value)}
                value={code}
                id="invitation-code-input"
              />
              <div className="register-submit-button-container">
                <GenericButton
                  submit
                  text={mvpMode ? "Activate" : "Activate account"}
                  onClick={
                    handleCodeRequest /* setIsActivationSet(true); return; */
                  }
                  className=""
                  padding="0.5vw 0.7vw"
                  width="6.8vw"
                  height="4vh"
                  hoverPadding="0.5vw 0.7vw"
                />
              </div>
              {error && (
                <CustomSnackBar
                  onClose={() => setError(undefined)}
                  open={error.length > 0}
                  text={error}
                  severity="error"
                />
              )}
            </div>
          </form>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4vw" }}
          >
            <TextInputField
              height="4.3vh"
              width="13vw"
              padding="0.5vw 0.7vw"
              title="Your email"
            />
            <TextInputField
              height="4.3vh"
              width="13vw"
              padding="0.5vw 0.7vw"
              title="Your username"
            />
            <TextInputField
              height="4.3vh"
              width="13vw"
              padding="0.5vw 0.7vw"
              title="Your password"
              type="password"
            />
            <TextInputField
              height="4.3vh"
              width="13vw"
              padding="0.5vw 0.7vw"
              title="Confirm your password"
              type="password"
            />
            <div className="register-submit-button-container">
              <div className="google-oauth-button">
                <GoogleIcon style={{ color: "white", fontSize: "0.8vw" }} />
                Sign up with Google
              </div>
              <GenericButton
                arrowIcon
                text="Sign up"
                onClick={() => {
                  return;
                }}
                className=""
                padding="0.3vw 0.4vw"
                width="5vw"
                height="4vh"
                hoverPadding="0.3vw 0.7vw"
              />
            </div>
            <div className="register-page-login-link">
              Already have an account?&nbsp;&nbsp;
              <a
                href="/login"
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Log in
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterPage;
