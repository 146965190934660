import React, { ReactNode } from "react";
import "./SeparationToolFooter.css";
import DragAndDropContainer from "../../components/DragAndDrop/DragAndDropContainer";
import SepToolPlayer from "../../components/SepToolPlayer/SepToolPlayer";
import { SplitButton } from "../../components/SplitButton/SplitButton";
import {
  InputsContext,
  InputsContextProps,
} from "../..//contexts/InputsContext";
import { GenericButton } from "../../components/UI/GenericButton";
import { theme } from "../../constants/theme";
import socket from "../../services/socketService";
import GenericModal from "../../components/UI/GenericModal/GenericModal";
import { genericFetch, GenericFetchProps } from "../../contexts/UserContext";
interface SeparationToolFooterProps {
  isSongUploaded: boolean;
  id?: string;
  setDeleted?: React.Dispatch<React.SetStateAction<boolean>>;
}
// MVP Mode
const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

const SeparationToolFooter: React.FC<SeparationToolFooterProps> = ({
  isSongUploaded = false,
  id = undefined,
  setDeleted,
}) => {
  const { addInput, currentSong } = React.useContext(
    InputsContext
  ) as InputsContextProps;
  const [droppedFiles, setDroppedFiles] = React.useState<File[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  React.useEffect(() => {
    const addFiles = async () => {
      await addInput(droppedFiles);
      setDroppedFiles([]);
    };
    if (droppedFiles.length > 0) {
      addFiles();
    }
  }, [droppedFiles, addInput]);

  const handleClick = async () => {
    if (id && mvpMode && setDeleted) {
      const response = await genericFetch({method: "DELETE", url: "/delete", body: { "id": id }} as GenericFetchProps)
      if (response.error) {
        console.log("Error while deleting project")
        return;
      }
      setDeleted(true);
    }
  };

  const deleteModal: ReactNode = (
    <div className="delete-modal">
      <div className="generic-medium-bold-text">
        Are you sure you want to delete this project ?
      </div>
      <div className="delete-modal-buttons">
        <GenericButton text="Delete" width="35%" onClick={handleClick} />
        <GenericButton
        onClick={() => setIsDeleteModalOpen(false)}
          width="35%"
          text="Cancel"
          color={theme.palette.darkGrey}
          hoverColor={theme.palette.lightGrey}
        />
      </div>
    </div>
  );

  return (
    <div
      className="sep-tool-footer-main-container"
      style={{ justifyContent: mvpMode ? "space-around" : "" }}
    >
      {isSongUploaded && !mvpMode && (
        <DragAndDropContainer
          acceptOnlyAudioFiles
          setDroppedFiles={setDroppedFiles}
          droppedFiles={droppedFiles}
        />
      )}
      <SepToolPlayer />
      {!mvpMode && (
        <div className="source-sep-source-sep-right-split-border-container">
          <div className="source-sep-source-sep-right-split-container">
            <div className="generic-medium-bold-text">Split track</div>
            <SplitButton
              width="50%"
              height="60%"
              input={currentSong}
              withAnim
            />
          </div>
        </div>
      )}
      {mvpMode && id && (
        <GenericButton
          text="Delete Project"
          width="10%"
          height="50%"
          padding="0"
          hoverPadding="0"
          color={theme.palette.red}
          hoverColor={theme.palette.darkRed}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      )}
      <GenericModal
        height={200}
        element={deleteModal}
        padding={4}
        open={isDeleteModalOpen}
        handleModalClose={() => setIsDeleteModalOpen(false)}
      />
    </div>
  );
};

export default SeparationToolFooter;
