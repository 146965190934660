import { Alert, Snackbar } from "@mui/material";
import { CSSProperties, FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./DragAndDropContainer.css";
import React from "react";
import { InputsContext, InputsContextProps } from "../../contexts/InputsContext";

interface DragAndDropProps {
  setDroppedFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  droppedFiles?: File[];
  maxFile?: number;
  acceptOnlyAudioFiles?: boolean;
  style?: CSSProperties;
  className?: string;
  height?: string;
  width?: string;
}

const DragAndDropContainer: FC<DragAndDropProps> = (props) => {
  const [isErrorUploadingFile, setIsErrorUploadingFile] =
    useState<boolean>(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] =
    useState<boolean>(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);
  const {inputs} = React.useContext(InputsContext) as InputsContextProps;

  React.useEffect(() => {
    if (isLoadingUploadingFile && inputs.length > 0) {
      setIsLoadingUploadingFile(false);
    }
  }, [inputs]);

  const onDropFile = (files: any, filesRejection: any, event: any) => {
    setIsLoadingUploadingFile(true);
    if (!files || files.length === 0) {
      setIsErrorUploadingFile(true);
      setIsLoadingUploadingFile(false);
      return;
    }

    if (props.droppedFiles && props.setDroppedFiles) {
      let newFiles = [...props.droppedFiles, ...files];
      props.setDroppedFiles(newFiles);
    }
    setIsUploadSuccess(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: props.acceptOnlyAudioFiles
      ? {
          "audio/wav": [".wav", ".flac"],
          "audio/raw": [".wav", ".flac"],
          "audio/mp3": [".mp3"],
        }
      : {},
    onDrop: onDropFile,
    maxFiles: props.maxFile ?? undefined,
    noKeyboard: true,
  });

  return (
    <div
      className={"drag-and-drop-container-border-container " + props.className}
      style={{ height: props.height, width: props.width, ...props.style }}
    >
      <div className="drag-and-drop-container-main-container">
        <Snackbar
          open={isErrorUploadingFile}
          onClose={() => setIsErrorUploadingFile(false)}
        >
          <Alert
            onClose={() => setIsErrorUploadingFile(false)}
            severity="error"
          >
            Error while uploading. Please try again later.
          </Alert>
        </Snackbar>
        <Snackbar
          open={isUploadSuccess}
          onClose={() => setIsUploadSuccess(false)}
        >
          <Alert onClose={() => setIsUploadSuccess(false)} severity="success">
            Files successfully uploaded!
          </Alert>
        </Snackbar>
        <div {...getRootProps({ className: "drag-and-drop-container" })}>
          <input {...getInputProps()} />
          {isLoadingUploadingFile ? (
            <div>Uploading...</div>
          ) : (
            <>
              {isDragActive ? (
                "Dragging ..."
              ) : (
                <div className="dd-bold-text-container">
                  + Add a song or a folder
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DragAndDropContainer;
