import "./Library.css";
import { LibraryRightComponent } from "./LibraryRightComponent/LibraryRightComponent";
import { LibraryLeftComponent } from "./LibraryLeftComponent/LibraryLeftComponent";
import ArrowIcon from "../../assets/icons/arrow-icon.svg";
import React from "react";
import { useKeyPress } from "../../hooks/UseKeyPress";
import {
  InputsContext,
  InputsContextProps,
} from "../../contexts/InputsContext";
export interface IconTitleProps {
  title: string;
  alt: string;
  icon: string;
  color?: string;
  width?: string;
  height?: string;
  padding?: string;
  hoverColor?: string;
  gap?: string;
  hoverEndIcon?: string;
  endIcon?: string;
  pointer?: boolean;
  onClick?: (event: any) => void;
  selected?: boolean;
}

export const IconTitle = (props: IconTitleProps) => {
  const [isHover, setIsHover] = React.useState(false);
  const { fetchUpload, deleteTracks, currentSong } = React.useContext(
    InputsContext
  ) as InputsContextProps;

  useKeyPress(
    ["Backspace"],
    async () => {
      if (currentSong && !document.getElementById("output-track-name")) deleteTracks(currentSong);
    },
    [8]
  );

  useKeyPress(
    ["Enter"],
    async () => {
      if (currentSong) await fetchUpload(currentSong);
    },
    [13]
  );

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="source-sep-icon-title"
      style={{
        backgroundColor:
          isHover || props.selected ? props.hoverColor : props.color,
        height: props.height,
        width: props.width,
        padding: props.padding,
        cursor: props.pointer ? "pointer" : "auto",
      }}
      onClick={props.onClick}
    >
      <div
        className="source-sep-icon-title-main-container"
        style={{
          gap: props.gap,
        }}
      >
        <img src={props.icon} alt={props.alt} />
        <div className="generic-medium-bold-text">{props.title}</div>
      </div>
      {!props.selected &&
        (props.endIcon || (props.hoverEndIcon && isHover)) && (
          <img
            alt="icon-title-icon"
            src={
              isHover && props.hoverEndIcon ? props.hoverEndIcon : props.endIcon
            }
          />
        )}
      {props.selected && <img alt="icon-title-icon" src={ArrowIcon} />}
    </div>
  );
};

export const Library: React.FC = () => {
  return (
    <div className="source-sep-library-container">
      <LibraryLeftComponent />
      <LibraryRightComponent />
    </div>
  );
};
