import { SplitButton } from "../../../../components/SplitButton/SplitButton";
import { GenericButton } from "../../../../components/UI/GenericButton";
import PlayerArrowIcon from "../../../../assets/icons/player-arrow-icon.svg";
import VolumeUpIcon from "../../../../assets/icons/volume-up-icon.svg";
import MusicIcon from "../../../../assets/icons/music-icon.svg";
import DropdownArrow from "../../../../assets/icons/dropdown-arrow-icon.svg";
import PauseIcon from "../../../../assets/icons/pause-icon.svg";
import React from "react";
import "./LibraryFooter.css";
import GenericDropdownBasic from "../../../../components/UI/GenericDropdown";
import { VolumeMute } from "@mui/icons-material";
import * as Tone from "tone";
import {
  PlayerContext,
  PlayerContextProps,
} from "../../../../contexts/PlayerContext";
import { computeTime } from "../../../../tools/computeTime";
import {
  SepModelsContext,
  SepModelsContextProps,
} from "../../../../contexts/SepModelsContext";
import {
  InputsContext,
  InputsContextProps,
} from "../../../../contexts/InputsContext";

interface LibraryFooterProps {
  openModal: boolean;
}

const LibraryFooter: React.FC<LibraryFooterProps> = (props) => {
  const {
    isPlaying,
    setIsPlaying,
    volume,
    setVolume,
    mute,
    setMute,
    currentTime,
    setCurrentTime,
    currentPlayer,
    setCurrentPlayer,
    timeProgressBar,
    togglePlayPause,
  } = React.useContext(PlayerContext) as PlayerContextProps;

  // Audio Playback
  const [duration, setDuration] = React.useState<number>(0);

  // Audio Volume
  const volumeBar = React.useRef<HTMLInputElement>(null);

  const { sepModels } = React.useContext(
    SepModelsContext
  ) as SepModelsContextProps;

  const [sepModel, setSepModel] = React.useState<string | undefined>(undefined);
  const { modifySepModel, currentSong } = React.useContext(
    InputsContext
  ) as InputsContextProps;

  React.useEffect(() => {
    if (sepModel && currentSong) {
      modifySepModel(currentSong, sepModel);
    }
  }, [sepModel, currentSong]);

  React.useEffect(() => {
    if (currentPlayer === undefined) {
      setDuration(0);
      setCurrentTime(0);
      if (timeProgressBar && timeProgressBar.current) {
        timeProgressBar.current.value = "0";
      }
    }
  }, [currentPlayer, setCurrentTime, timeProgressBar]);

  const initPlayer = React.useCallback(() => {
    Tone.start();
    if (currentSong === undefined) return;
    const newPlayer = new Tone.Player().sync().start(0).toDestination();
    newPlayer.buffer.set(currentSong.audioBuffer);
    setCurrentTime(0);
    return { newPlayer };
  }, [currentSong, setCurrentTime]);

  React.useEffect(() => {
    if (currentSong === undefined) return;

    const { newPlayer } = initPlayer() as { newPlayer: Tone.Player };
    setCurrentPlayer((prevPlayer) => {
      if (prevPlayer) {
        prevPlayer.disconnect();
        prevPlayer.dispose();
      }
      return newPlayer;
    });
    setDuration(currentSong.duration);
  }, [currentSong, setCurrentPlayer, initPlayer]);

  React.useEffect(() => {
    if (togglePlayPause === undefined) return;
    togglePlayPause();
  }, [isPlaying, togglePlayPause]);

  // Update timeProgressBar value and currentTime
  React.useEffect(() => {
    if (timeProgressBar === undefined) return;
    let intervalId: NodeJS.Timer;
    if (isPlaying) {
      intervalId = setInterval(() => {
        if (currentPlayer && isPlaying && timeProgressBar.current) {
          const currentTime = Tone.Transport.seconds;
          if (currentTime < duration) {
            timeProgressBar.current.value = String(currentTime);
            setCurrentTime(currentTime);
          } else {
            setIsPlaying(false);
            timeProgressBar.current.value = String(0);
            setCurrentTime(0);
            Tone.Transport.stop();
          }
        }
      }, 16);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isPlaying, duration, currentPlayer, setIsPlaying, setCurrentTime, timeProgressBar]);

  const changeVolume = () => {
    if (currentPlayer && volumeBar.current) {
      if (currentPlayer.mute) return;
      currentPlayer.volume.value = Number(volumeBar.current.value);
      setVolume(Number(volumeBar.current.value));
    }
  };

  const backwardAudio = () => {
    if (currentSong === undefined || timeProgressBar === undefined) return;
    if (currentPlayer === undefined || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) -
        currentSong.audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const changeTimePosition = () => {
    if (currentPlayer === undefined || !timeProgressBar.current) return;
    const currentTimeValue = Number(timeProgressBar.current.value);
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(currentTimeValue / duration) * 100}%`
    );
    setCurrentTime(currentTimeValue);
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  const forwardAudio = () => {
    if (currentSong === undefined) return;
    if (currentPlayer === undefined || !timeProgressBar.current) return;
    timeProgressBar.current.value = String(
      Number(timeProgressBar.current.value) +
        currentSong.audioBuffer.duration / 5
    );
    timeProgressBar.current.style.setProperty(
      "--seek-before-width",
      `${(Number(timeProgressBar.current.value) / duration) * 100}%`
    );
    setCurrentTime(Number(timeProgressBar.current.value));
    Tone.Transport.seconds = Number(timeProgressBar.current.value);
  };

  // END OF PLAYER PART

  React.useEffect(() => {
    if (currentPlayer) {
      currentPlayer.mute = mute;
    }
  }, [mute, currentPlayer]);

  return (
    <div className="source-sep-library-right-footer">
      {props.openModal && <div className="overlay"></div>}
      <div className="source-sep-library-right-player-container">
        <GenericButton
          textClassName="generic-medium-bold-text"
          text={currentSong?.title ?? "Select a song"}
          icon={MusicIcon}
          iconWidth="1vw"
          iconHeight="auto"
          width="20%"
          padding="1%"
          hoverPadding="1%"
          withoutHover
        />
        <div
          style={{ height: "2vh", width: "2px", backgroundColor: "white" }}
        ></div>
        <div className="source-sep-library-right-player-buttons">
          <img
            src={PlayerArrowIcon}
            alt="go-backward-arrow"
            onClick={backwardAudio}
          />
          <div
            className="source-sep-library-right-button-container"
            onClick={() => setIsPlaying(!isPlaying)}
          >
            <img
              src={isPlaying ? PauseIcon : DropdownArrow}
              alt="play-button"
              style={{
                transform: isPlaying ? "" : "rotate(90deg)",
                height: "40%",
                width: "auto",
              }}
            />
          </div>
          <img
            src={PlayerArrowIcon}
            alt="go-forward-arrow"
            style={{ transform: "rotate(180deg)" }}
            onClick={forwardAudio}
          />
        </div>
        <div className="source-sep-library-right-timer">
          <div className="generic-medium-bold-text">
            {computeTime(currentTime)}
          </div>
          <input
            type="range"
            className="audio-progress-bar"
            ref={timeProgressBar}
            value={currentTime}
            onChange={changeTimePosition}
            min={0}
            max={currentSong?.duration ?? 0}
            step={0.01}
          />
          <div className="generic-medium-bold-text">
            {computeTime(currentSong?.duration ?? 0)}
          </div>
        </div>
        <div
          style={{ height: "2vh", width: "2px", backgroundColor: "white" }}
        ></div>
        <div className="source-sep-library-right-volume-container">
          <div
            className="source-sep-library-right-button-container"
            onClick={() => setMute(!mute)}
          >
            {mute ? (
              <VolumeMute style={{ height: "40%", width: "auto" }} />
            ) : (
              <img
                src={VolumeUpIcon}
                alt="play-button"
                style={{
                  height: "40%",
                  width: "auto",
                }}
              />
            )}
          </div>
          <input
            type="range"
            className="audio-progress-bar"
            ref={volumeBar}
            onChange={changeVolume}
            value={volume}
            max={10}
            min={-30}
            step={1}
          />
        </div>
      </div>
      <div className="source-sep-library-right-model-container">
        <GenericDropdownBasic
          default={currentSong?.sepModel ?? "Select a model"}
          items={sepModels}
          currentSelectedItem={currentSong?.sepModel ?? "Select a model"}
          setItemCallback={setSepModel}
        />
      </div>
      <div className="source-sep-library-right-split-all-container">
        <div className="generic-medium-bold-text">Split all tracks</div>
        <SplitButton width="50%" input={currentSong} withAnim />
      </div>
    </div>
  );
};

export default LibraryFooter;
