import React, { useCallback, useEffect } from "react";
import {
  ISepModel,
  SepModelsContext,
  SepModelsContextProps,
} from "./SepModelsContext";
import { UserContext, UserContextProps } from "./UserContext";
import socket from "../services/socketService";

export interface InputsContextProps {
  inputs: IInput[];
  setInputs: React.Dispatch<React.SetStateAction<IInput[]>>;
  addInput: (files: File[]) => Promise<void>;
  removeInput: (input: IInput) => void;
  addInputToAdded: (input: ISong) => void;
  isDirectory: (input: IInput) => boolean;
  isSong: (input: IInput) => boolean;
  openDirectory: (dir: IDirectory) => void;
  modifySepModel: (input: ISong, model: string) => void;
  setOutputTracks: (input: ISong, tracks: ITrack[], id: string) => void;
  getInput: (input: IInput, temp: IInput[]) => IInput | undefined;
  getAllInputsSongs: () => ISong[];
  fetchUpload: (input: ISong) => Promise<boolean | undefined>;
  fetchTaskResults: (taskId: string, input: ISong) => Promise<boolean | undefined>;
  deleteTracks: (input: ISong) => void;
  currentOutputTracks?: IOutput;
  setCurrentOutputTracks: React.Dispatch<
    React.SetStateAction<IOutput | undefined>
  >;
  removeInputFromAdded: (input: ISong) => void;
  currentSong?: ISong;
  setCurrentSong: React.Dispatch<React.SetStateAction<ISong | undefined>>;
  IsGenerationError : boolean;
  setGenerationError : React.Dispatch<React.SetStateAction<boolean>>;
  modifyTrackTitle: (input: ISong, track: ITrack, title: string) => void;
  rescaleBuffer: (input: ITrack, maxDuration: number, maxSampleRate: number, offset?: number) => ITrack;
}

export const InputsContext = React.createContext<null | InputsContextProps>(
  null
);

export interface ContextProviderProps {
  children: React.ReactNode;
}

export type IInput = IDirectory | ISong;

export interface IDirectory {
  title: string;
  songs: ISong[];
  open?: boolean;
}

export interface ITrack {
  type: string;
  color?: string;
  audioBuffer: AudioBuffer;
  name: string;
  blob: Blob;
}

export interface IOutput {
  model: string;
  tracks: ITrack[] | undefined;
  id?: string;
}

export interface ISong {
  file?: File;
  audioBuffer: AudioBuffer;
  title: string;
  duration: number;
  added: boolean;
  artist: string | undefined;
  format: string | undefined;
  date?: number;
  outputs: IOutput[];
  taskId?: string;
  sepModel: string;
  contentDetails?: JSX.Element;
}

export const InputsContextProvider = ({ children }: ContextProviderProps) => {
  const [inputs, setInputs] = React.useState<IInput[]>([]);
  const { sepModels, getModelIndex, setCurrentSelectedSepModelIsGenerated } =
    React.useContext(SepModelsContext) as SepModelsContextProps;
  const [currentOutputTracks, setCurrentOutputTracks] =
    React.useState<IOutput>();
  const [currentSong, setCurrentSong] = React.useState<undefined | ISong>(
    undefined
  );
  const [IsGenerationError, setGenerationError] = React.useState<boolean>(false);
  const { userEmail, userId, userToken, setUserToken } = React.useContext(UserContext) as UserContextProps;

  // const fetchTaskResults = async (taskId: string, input: ISong) => {
  //   const res = await fetch(
  //     `${process.env.REACT_APP_SERVER_URL}/task/${taskId}`
  //   );
  //   if (res.status === 200) {
  //     const temp = await res.json();
  //     const tempAudios: ITrack[] = [];
  //     await Promise.all(
  //       temp.outputs.map(async (output: any, index: number) => {
  //         const filename = output.substring(output.lastIndexOf("/") + 1);
  //         const res = await fetch(
  //           `${process.env.REACT_APP_SERVER_URL}/media/` +
  //             temp.id +
  //             "/" +
  //             filename
  //         );
  //         if (res.ok) {
  //           const audioContext = new AudioContext();
  //           const blob = await res.blob();
  //           const file = new File([blob], filename, { type: blob.type });
  //           const audioData = await file.arrayBuffer();
  //           const audioBuffer = await audioContext.decodeAudioData(audioData);
  //           tempAudios.push({
  //             type: temp.types[index][0],
  //             color: temp.types[index][1],
  //             audioBuffer,
  //             name: filename,
  //             blob: blob,
  //           });
  //         }
  //       })
  //     );
  //     setOutputTracks(input, tempAudios, temp.id);
  //     setCurrentSelectedSepModelIsGenerated(true);
  //     setCurrentOutputTracks({
  //       model: input.sepModel,
  //       tracks: tempAudios,
  //     });
  //     return true;
  //   }
  //   if (res.status === 202) {
  //     return false;
  //   }
  //   return undefined;
  // }

  // const setOutputTracks = (input: ISong, tracks: ITrack[], id: string) => {
  //   let temp = [...inputs];
  //   let found = temp.find(
  //     (song) =>
  //       song === input ||
  //       (isDirectory(song) &&
  //         (song as IDirectory).songs.map((elem) => elem === input))
  //   );
  //   if (found && isSong(found)) {
  //     if (!input.sepModel) return;
  //     let output = (found as ISong).outputs[getModelIndex(input.sepModel)];
  //     output.tracks = tracks;
  //     output.id = id;
  //   } else if (found) {
  //     if (!input.sepModel) return;
  //     let temp = (found as IDirectory).songs.find((song) => song === input);
  //     if (temp) {
  //       let output = temp.outputs[getModelIndex(input.sepModel)]
  //       output.tracks = tracks;
  //       output.id = id;
  //     }
  //   }
  //   setInputs(temp);
  // };

  const setOutputTracks = useCallback((input: ISong, tracks: ITrack[], id: string) => {
    let temp = [...inputs];
    let found = temp.find(
      (song) =>
        song === input ||
        (isDirectory(song) &&
          (song as IDirectory).songs.map((elem) => elem === input))
    );
    if (found && isSong(found)) {
      if (!input.sepModel) return;
      let output = (found as ISong).outputs[getModelIndex(input.sepModel)];
      output.tracks = tracks;
      output.id = id;
    } else if (found) {
      if (!input.sepModel) return;
      let temp = (found as IDirectory).songs.find((song) => song === input);
      if (temp) {
        let output = temp.outputs[getModelIndex(input.sepModel)]
        output.tracks = tracks;
        output.id = id;
      }
    }
    setInputs(temp);
  }, [inputs, setInputs, getModelIndex]);

  const fetchTaskResults = useCallback(async (taskId: undefined | string, input: ISong, userToken?: string) => {
    const res = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/task/${taskId}`, {
      headers: {
        "Authorization": `Bearer ${userToken}`
      }
    }
    );
    if (res.status === 200) {
      const temp = await res.json();
      const tempAudios: ITrack[] = [];
      await Promise.all(
        temp.outputs.map(async (output: any, index: number) => {
          const filename = output.substring(output.lastIndexOf("/") + 1);
          const res = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/media/` +
            temp.id +
            "/" +
            filename
          );
          if (res.ok) {
            const audioContext = new AudioContext();
            const blob = await res.blob();
            const file = new File([blob], filename, { type: blob.type });
            const audioData = await file.arrayBuffer();
            const audioBuffer = await audioContext.decodeAudioData(audioData);
            tempAudios.push({
              type: temp.types[index][0],
              color: temp.types[index][1],
              audioBuffer,
              name: filename,
              blob: blob,
            });
          }
        })
      );
      setOutputTracks(input, tempAudios, temp.id);
      setCurrentSelectedSepModelIsGenerated(true);
      setCurrentOutputTracks({
        model: input.sepModel,
        tracks: tempAudios,
      });
      return true;
    }
    if (res.status === 202) {
      return false;
    }
    return undefined;
  }, [setCurrentSelectedSepModelIsGenerated, setOutputTracks]);

  const handleTaskEnded = async () => {
    setCurrentSong((prev) => {
      if (prev && prev.taskId) {
        setUserToken(token => { fetchTaskResults(prev.taskId, prev, token); return token }
        )
      }
      return prev;
    })
  }

  useEffect(() => {
    socket.on("task_ended", handleTaskEnded)
    return () => {
      socket.off("task_ended", handleTaskEnded);  // Cleanup the event listener on component unmount
    };
  }, []) 

  useEffect(() => {
    socket.on("task_error", handleTaskError)
    return () => {
      socket.off("task_error", handleTaskError);  // Cleanup the event listener on component unmount
    };
  }, []) 

  const handleTaskError = async () => {
    setGenerationError(true);
  }

  const rescaleBuffer = useCallback((input: ITrack, maxDuration: number, maxSampleRate: number, offset?: number) => {
    const newBuffer = new AudioBuffer({
      length: maxDuration * maxSampleRate,
      numberOfChannels: input.audioBuffer.numberOfChannels,
      sampleRate: maxSampleRate,
    });
    for (let channel = 0; channel < input.audioBuffer.numberOfChannels; channel++) {
      const oldData = input.audioBuffer.getChannelData(channel);
      newBuffer.copyToChannel(oldData, channel, offset ? offset * maxSampleRate : 0);
    }
    input.audioBuffer = newBuffer;
    return input;
  }, []);

  const modifyTrackTitle = (input: ISong, track: ITrack, title: string) => {
    let tracks = undefined;
    let temp = [...inputs];
    let found = getInput(input, temp);
    if (found && isDirectory(found)) {
      let song = (found as IDirectory).songs.find((song) => song === input);
      if (song) {
        tracks = song.outputs[getModelIndex(song.sepModel)].tracks;
      }
    } else if (found && isSong(found)) {
      tracks = (found as ISong).outputs[
        getModelIndex((found as ISong).sepModel)
      ].tracks;
    }
    if (tracks) {
      let found = tracks.find(elem => track === elem);
      if (found)
        found.name = title;
    }
    setInputs(temp);
  }

  const isDirectory = (input: IInput) => {
    return (input as IDirectory).songs !== undefined;
  };

  const isSong = (input: IInput) => {
    return (input as ISong).audioBuffer !== undefined;
  };

  const addInput = async (files: File[]) => {
    let temp: IInput[] = [];
    for (let file of files) {
      const audioContext = new AudioContext();
      try {
        const audioData = await file.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(audioData);
        const newInput: ISong = {
          title: file.name,
          audioBuffer: audioBuffer,
          duration: audioBuffer.duration,
          added: false,
          format: "." + file.name.split(".").pop(),
          artist: '-',
          date: file.lastModified,
          sepModel: sepModels[0].title,
          outputs: sepModels.map((model: ISepModel) => ({
            model: model.title,
            tracks: undefined,
            id: undefined,
          })),
          file: file,
          contentDetails: <>Model {sepModels[0].title}</>,
        };
        const dir = (file as any).path.split("/")[
          (file as any).path.split("/").length - 2
        ];
        if (dir) {
          const found = temp.find(
            (elem) => isDirectory(elem) && (elem as IDirectory).title === dir
          );
          if (found) {
            (found as IDirectory).songs.push(newInput);
          } else {
            const tempDir: IDirectory = {
              title: dir,
              songs: [newInput],
              open: true,
            };
            temp.push(tempDir);
          }
        } else {
          temp.push(newInput);
        }
      } catch (error) {
        console.error("Error loading audio file:", error);
      }
    }
    setInputs([...inputs, ...temp]);
  };

  const fetchUpload = async (input: ISong) => {
    if (!input.outputs[getModelIndex(input.sepModel)].tracks && userId && userToken) {
      if (!input.file) return;
      const blob = new Blob([input.file], {
        type: input.file.type,
      });
      const isoDate = input.date ? new Date(input.date).toISOString() : undefined;
      const audio_infos = {
        "Name": input.title,
        "Date": isoDate,
        "Duration": input.duration,
        "Format": input.format,
        "Artist": input.artist,
      }
      const body = new FormData();
      body.append("email", userEmail ? userEmail : "");
      body.append("audio_infos", JSON.stringify(audio_infos));
      body.append("user_id", userId)
      body.append("model", input.sepModel);
      body.append("input", blob);

      try {
        const res = await fetch(process.env.REACT_APP_SERVER_URL + "/upload-and-generate", {
          headers: {
            "Authorization": `Bearer ${userToken}`,
          },
          method: "POST",
          body: body,
        });
        if (res.status === 200) {
          const temp = await res.json();
          await Promise.all(
            input.taskId = temp.task_id,
          );
        } else if (res.status === 401) {
          return false;
        } else {
          console.log("Error during fetch: ", res);
          return;
        }
      } catch (e) {
        console.log("Error", e);
      }
    }
    return true;
  };

  const getAllInputsSongs = () => {
    let temp: ISong[] = [];
    inputs.forEach((input) => {
      if (isDirectory(input)) {
        temp = [...temp, ...(input as IDirectory).songs];
      } else {
        temp.push(input as ISong);
      }
    });
    return temp;
  };

  const getInput = (input: IInput, temp: IInput[]) => {
    let found = temp.find(
      (song) =>
        song === input ||
        (isDirectory(song) &&
          (song as IDirectory).songs.map((elem) => elem === input))
    );
    return found;
  };

  const modifySepModel = useCallback(
    (input: ISong, model: string) => {
      let temp = [...inputs];
      let found = temp.find(
        (song) =>
          song === input ||
          (isDirectory(song) &&
            (song as IDirectory).songs.map((elem) => elem === input))
      );
      if (found && isSong(found)) {
        let temp = found as ISong;
        if (temp.sepModel) {
          temp.sepModel = model;
        }
        temp.contentDetails = <>Model: {model}</>;
      } else if (found) {
        let temp = (found as IDirectory).songs.find((song) => song === input);
        if (temp) {
          if (temp.sepModel) {
            temp.sepModel = model;
          }
          temp.contentDetails = <>Model: {model}</>;
        }
      }
      setInputs(temp);
    },
    [inputs]
  );

  const openDirectory = (dir: IDirectory) => {
    const temp = [...inputs];
    let found = temp.find((input) => input === dir);
    if (found) {
      (found as IDirectory).open = !(found as IDirectory).open;
      setInputs(temp);
    }
  };

  const addInputToAdded = (input: ISong) => {
    let temp = [...inputs];
    let found = temp.find(
      (elem) =>
        elem === input ||
        (isDirectory(elem) &&
          (elem as IDirectory).songs.find((song) => song === input))
    );
    if (found && isDirectory(found)) {
      let song = (found as IDirectory).songs.find((song) => song === input);
      if (song) song.added = true;
    } else if (found) (found as ISong).added = true;
    setInputs(temp);
  };

  const removeInputFromAdded = (input: ISong) => {
    let temp = [...inputs];
    let found = temp.find(
      (elem) =>
        elem === input ||
        (isDirectory(elem) &&
          (elem as IDirectory).songs.find((song) => song === input))
    );
    if (found && isDirectory(found)) {
      let song = (found as IDirectory).songs.find((song) => song === input);
      if (song) song.added = false;
    } else if (found) (found as ISong).added = false;
    setInputs(temp);
  };

  const removeInput = (input: IInput) => {
    let temp = [...inputs];
    temp.filter((elem) => elem !== input);
    setInputs(temp);
  };

  const deleteTracks = (input: ISong) => {
    if (input.outputs[getModelIndex(input.sepModel)].tracks) {
      let temp = [...inputs];
      let found = getInput(input, temp);
      if (found && isDirectory(found)) {
        let song = (found as IDirectory).songs.find((song) => song === input);
        if (song) song.outputs[getModelIndex(song.sepModel)].tracks = undefined;
      } else if (found && isSong(found))
        (found as ISong).outputs[
          getModelIndex((found as ISong).sepModel)
        ].tracks = undefined;
      setInputs(temp);
    }
  };

  const contextValue = {
    inputs,
    setInputs,
    addInput,
    removeInput,
    addInputToAdded,
    isDirectory,
    isSong,
    openDirectory,
    modifySepModel,
    setOutputTracks,
    getInput,
    getAllInputsSongs,
    fetchUpload,
    fetchTaskResults,
    deleteTracks,
    currentOutputTracks,
    setCurrentOutputTracks,
    removeInputFromAdded,
    currentSong,
    setCurrentSong,
    modifyTrackTitle,
    rescaleBuffer,
    IsGenerationError,
    setGenerationError,
  };

  return (
    <InputsContext.Provider value={contextValue}>
      {children}
    </InputsContext.Provider>
  );
};
