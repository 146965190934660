import './TextInputField.css';

interface TextInputFieldProps {
    height?: string;
    width?: string;
    padding?: string;
    onChange?: (e: any) => void;
    title?: string;
    type?: React.HTMLInputTypeAttribute;
    style?: any;
    value?: any;
    id?: string;
    onKeyPress?: (e: any) => void;
}

export default function TextInputField(props: TextInputFieldProps) {
    return (
        <div className="text-input-container" style={props.style}>
            <div className="text-field-title">{props.title}</div>
            <input
                className="text-input-field-box"
                onChange={(e: any) => { e.preventDefault(); if (props.onChange) props.onChange(e) }}
                style={{
                    height: props.height,
                    width: props.width,
                    color: "white",
                    padding: props.padding,
                    border: "transparent",
                    outline: "none",
                    margin: 0,
                }}
                maxLength={60}
                type={props.type}
                value={props.value}
                id={props.id}
                onKeyUp={props.onKeyPress}
            />
        </div>
    );
}
