import React, { CSSProperties } from "react";
import DropdownArrow from "../../assets/icons/dropdown-arrow-icon.svg";
import DotsIcon from "../../assets/icons/dots-icon.svg";
import "./Generic.css";
import "./GenericDropdown.css";
import SimpleSVGComponent from "./SimpleSVGComponent";

interface GenericDropdownBasicProps {
  backgroundColor?: string;
  style?: CSSProperties;
  className?: string;
  default?: string;
  position?: "top" | "bottom";
  icon?: string | undefined;
  iconStyle?: CSSProperties;
  items: any[];
  currentSelectedItem: any;
  setItemCallback: (item: any) => void;
}

interface GenericDropDownBasicItemProps {
  index: number;
  allItems: any[];
  item: any;
  title: string;
  setItemCallback: (item: any) => void;
  currentSelectedItem: any;
  detailModalContent?: JSX.Element;
  position?: "bottom" | "top";
}

const GenericDropDownBasicItem: React.FC<GenericDropDownBasicItemProps> = ({
  index,
  allItems,
  item,
  title,
  setItemCallback,
  currentSelectedItem,
  detailModalContent,
  position
}) => {
  const [isHover, setIsHover] = React.useState(false);

  return (
    <div
      key={index}
      className="generic-dropdown-item-container"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <div className="generic-dropdown-modal generic-small-text">
          {detailModalContent}
        </div>
      )}
      <div
        className="generic-medium-bold-text generic-dropdown-item"
        onClick={() => {
          if (currentSelectedItem !== item && currentSelectedItem !== item.title) setItemCallback(item);
        }}
        style={{
          backgroundColor:
            item === currentSelectedItem || (item.title === currentSelectedItem) ||
            (!currentSelectedItem && index === 0 && !title)
              ? "#1a191e"
              : "",
        }}
      >
        <span style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden', width: "100%", textAlign: "left" }} >
        {title}
        </span>
        <div className={isHover ? "generic-dropdown-dots-container" : ""}>
          <img src={DotsIcon} alt="dots-icon" />
        </div>
      </div>
      {index + 1 !== allItems.length && (
        <div
          className="underline"
          style={{ background: "white", width: "85%" }}
        ></div>
      )}
    </div>
  );
};

export const GenericDropdownBasic = (props: GenericDropdownBasicProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      onClick={() => { if (props.items.length > 0) setIsOpen(!isOpen)}}
      className={"generic-dropdown-container " + props.className}
      style={{
        backgroundColor: isOpen ? "#3f4043" : props.backgroundColor,
        borderRadius:
          isOpen && props.position === "bottom"
            ? "10px 10px 0 0"
            : isOpen
            ? "0 0 10px 10px"
            : "",
        ...props.style,
      }}
    >
      <div className="generic-medium-bold-text" style={{ display: "flex", gap: '0.4vw', justifyContent: 'center', alignItems: 'center' }}>
        {props.icon && ( <SimpleSVGComponent  style={props.iconStyle} icon={props.icon} alt="icon" /> )}
        <span style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden', width: "7vw" }} >
          {props.currentSelectedItem?.title ?? props.default ?? props.items[0].title}
        </span>
      </div>
      {props.items.length > 0 &&
      <img
        alt="drodown-arrow"
        src={DropdownArrow}
        style={{ transform: isOpen ? "" : "rotate(180deg)" }}
      />
      }
      {isOpen && (
        <div
          className="generic-dropdown-list"
          style={{
            top: props.position === "bottom" ? "100%" : "",
            borderRadius: props.position === "bottom" ? "0 0 10px 10px" : "",
          }}
        >
          {props.items.length > 0 &&
            props.items.map((item: any, index) => (
              <GenericDropDownBasicItem
                position={props.position}
                key={index}
                index={index}
                item={item}
                allItems={props.items}
                title={item.title ? item.title : item}
                setItemCallback={props.setItemCallback}
                currentSelectedItem={props.currentSelectedItem}
                detailModalContent={item.contentDetails ? item.contentDetails : undefined}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default GenericDropdownBasic;
