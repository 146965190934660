import { GenericButton } from "../../../components/UI/GenericButton";
import React from "react";
import MusicIcon from "../../../assets/icons/music-icon.svg";
import FolderIcon from "../../../assets/icons/folder-icon.svg";
import SearchIcon from "../../../assets/icons/search-icon.svg";
import ScrollArrow from "../../../assets/icons/scroll-arrow-icon.svg";
import PlusIcon from "../../../assets/icons/plus-icon.png";
import { IconTitle } from "../Library";
import DropdownArrow from "../../../assets/icons/dropdown-arrow-icon.svg";
import "./LibraryLeftComponent.css";
import {
  IDirectory,
  ISong,
  InputsContext,
  InputsContextProps,
} from "../../../contexts/InputsContext";
import DragAndDropContainer from "../../../components/DragAndDrop/DragAndDropContainer";

export const LibraryLeftComponent: React.FC = () => {
  const {
    inputs,
    addInput,
    isSong,
    isDirectory,
    openDirectory,
    addInputToAdded,
  } = React.useContext(InputsContext) as InputsContextProps;
  const [droppedFiles, setDroppedFiles] = React.useState<File[]>([]);
  const [filter, setFilter] = React.useState<undefined | "folder" | "song">(
    undefined
  );
  const [sortAlphabet, setSortAlphabet] = React.useState<
    undefined | "A-Z" | "Z-A"
  >(undefined);
  const [currentInputs, setCurrentInputs] = React.useState(inputs);
  const [search, setSearch] = React.useState<undefined | string>(undefined);

  React.useEffect(() => {
    const addFiles = async () => {
      await addInput(droppedFiles);
      setDroppedFiles([]);
    };
    if (droppedFiles.length > 0) {
      addFiles();
    }
  }, [droppedFiles, addInput]);

  const handleFilterChange = (newFilter: "folder" | "song") => {
    if (filter === newFilter) {
      setFilter(undefined);
    } else setFilter(newFilter);
  };

  React.useEffect(() => {
    if (inputs.length > 0) {
      let temp = [...inputs];
      if (search && search.length  > 0) {
        temp = temp.filter(input => input.title.toLowerCase().includes(search.toLowerCase()));
      }
      if (filter) {
        temp = temp.filter((input) => {
          if (filter === "song" && isSong(input)) return true;
          else if (filter === "folder" && isDirectory(input)) return true;
          else return false;
        });
      }
      if (sortAlphabet === "A-Z") {
        temp.sort((a, b) => {
          return (a.title.localeCompare(b.title) - b.title.localeCompare(a.title));
        });
      }
      if (sortAlphabet === "Z-A") {
        temp.sort((a, b) => {
          return (b.title.localeCompare(a.title) - a.title.localeCompare(b.title));
        });
      }
      setCurrentInputs(temp);
    }
  }, [filter, sortAlphabet, inputs, search, isSong, isDirectory]);

  return (
    <div className="source-sep-library-left-container">
      <div className="source-sep-library-left-main">
        <div className="source-sep-library-left-header">
          <GenericButton text="Your library" width="25%" withoutHover />
          <div className="source-sep-library-left-header-icons">
            <img
              onClick={() => handleFilterChange("folder")}
              style={{
                padding: "7%",
                backgroundColor: filter === "folder" ? "#3F4043" : "",
              }}
              className="source-sep-library-icon-button"
              alt="folder-icon"
              src={FolderIcon}
            />
            <img
              onClick={() => handleFilterChange("song")}
              style={{
                padding: "7%",
                backgroundColor: filter === "song" ? "#3F4043" : "",
              }}
              className="source-sep-library-icon-button"
              alt="music-icon"
              src={MusicIcon}
            />
            <div
              onClick={() => {
                if (!sortAlphabet) setSortAlphabet("A-Z");
                else if (sortAlphabet === "A-Z") setSortAlphabet("Z-A");
                else if (sortAlphabet === "Z-A") setSortAlphabet(undefined);
              }}
              className="source-sep-library-icon-button generic-medium-bold-text"
              style={{ backgroundColor: sortAlphabet ? "#3F4043" : "" }}
            >
              {sortAlphabet ?? "A-Z"}
            </div>
          </div>
        </div>
        <div className="source-sep-library-left-searchbar">
          <img alt="loupe-icon" src={SearchIcon} />
          <input
            className="source-sep-library-searchbar-input"
            placeholder="Search for a song"
            onChange={(e: any) => setSearch(e.target.value)}
          />
        </div>
        <div className="source-sep-library-left-songs">
          {currentInputs.map((elem, index) =>
            isDirectory(elem) ? (
              <div className="library-left-folder-container" key={index}>
                <IconTitle
                  key={index}
                  pointer
                  alt="music-icon"
                  icon={FolderIcon}
                  title={(elem as IDirectory).title}
                  color="#1F1E22"
                  hoverColor="#2A2930"
                  hoverEndIcon={DropdownArrow}
                  /* endIcon={elem.added ? ArrowIcon : undefined} */
                  height="4vh"
                  onClick={() => openDirectory(elem as IDirectory)}
                />
                {(elem as IDirectory).open &&
                  (elem as IDirectory).songs.map((song, index) => (
                    <IconTitle
                      key={index}
                      selected={song.added}
                      pointer
                      alt="music-icon"
                      icon={MusicIcon}
                      title={song.title}
                      color="#1F1E22"
                      hoverColor="#2A2930"
                      hoverEndIcon={PlusIcon}
                      /* endIcon={song.added ? ArrowIcon : undefined} */
                      height="4vh"
                      width="85%"
                      onClick={() => addInputToAdded(song)}
                    />
                  ))}
              </div>
            ) : (
              <IconTitle
                key={index}
                selected={(elem as ISong).added}
                pointer
                alt="music-icon"
                icon={MusicIcon}
                title={(elem as ISong).title}
                color="#1F1E22"
                hoverColor="#2A2930"
                hoverEndIcon={PlusIcon}
                /* endIcon={(elem as ISong).added ? ArrowIcon : undefined} */
                height="10%"
                onClick={() => addInputToAdded(elem as ISong)}
              />
            )
          )}
        </div>
        <div className="source-sep-library-main-footer">
          <div className="generic-small-text">{inputs.length} songs</div>
          <div className="source-sep-library-main-arrows">
            <img alt="arrow-up" src={ScrollArrow} />
            <img
              alt="arrow-down"
              src={ScrollArrow}
              style={{ transform: "rotate(180deg)" }}
            />
          </div>
        </div>
      </div>
      <DragAndDropContainer
        width={"100%"}
        height="14%"
        acceptOnlyAudioFiles
        setDroppedFiles={setDroppedFiles}
        droppedFiles={droppedFiles}
      />
    </div>
  );
};
