import React from 'react';
import "./SeparationToolHeader.css";
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@mui/icons-material/ZoomOutOutlined';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FoldIcon from '../../assets/septool/fold-icon.svg';
import UnfoldIcon from '../../assets/septool/unfold-icon.svg';
import SimpleSVGComponent from "../../components/UI/SimpleSVGComponent";
import { theme } from "../../constants/theme";

interface SeparationToolHeaderProps {
    title?: string;
    isFolded?: boolean;
    setIsFolded?: (isFolded: boolean) => void;
    zoom?: number;
    maxZoom?: number;
    minZoom?: number;
    zoomIn?: () => void;
    zoomOut?: () => void;
    setZoom?: (zoom: number) => void;
}

const SeparationToolHeader: React.FC<SeparationToolHeaderProps> = (
    {
        title = "Separation stems",
        isFolded = true,
        setIsFolded = () => { },
        zoom = 1,
        maxZoom = 2,
        minZoom = 1,
        zoomIn = () => { },
        zoomOut = () => { },
        setZoom = () => { }
    }
) => {
    return (
    <div className="separation-tool-header-container">
        <div className="separation-tool-header-title">
            Separation stems
        </div>
        <div className="separation-tool-header-tools">
            <div className="separation-tool-header-zoom">
                <ZoomOutOutlinedIcon sx={{ color: (Math.ceil(zoom) === minZoom) ? theme.palette.darkGrey : 'white', height: '1.1vw', width: '1.1vw', '&:hover': { cursor: (Math.ceil(zoom) === minZoom) ? "" : 'pointer' } }} onClick={zoomOut} />
                <RotateLeftIcon sx={{ color: (Math.ceil(zoom) === minZoom) ? theme.palette.darkGrey : 'white', height: '0.8vw', width: '0.8vw', marginRight: '0.1vw', '&:hover': { cursor: (Math.ceil(zoom) === minZoom) ? "" : 'pointer' } }} onClick={() => setZoom(1)} />
                <ZoomInOutlinedIcon sx={{ color: (Math.ceil(zoom) === maxZoom) ? theme.palette.darkGrey : 'white', height: '1.1vw', width: '1.1vw', '&:hover': { cursor: (Math.ceil(zoom) === maxZoom) ? "" : 'pointer' } }} onClick={zoomIn} />
            </div>
            <div className="separation-tool-header-fold" onClick={() => setIsFolded(!isFolded)}>
                {!isFolded ?
                    <SimpleSVGComponent alt="fold-icon" icon={FoldIcon} height="0.5vw" width="0.5vw" />
                    :
                    <SimpleSVGComponent alt="unfold-icon" icon={UnfoldIcon} height="0.5vw" width="0.5vw" />
                }
            </div>
        </div>
    </div>
    );
};

export default SeparationToolHeader;