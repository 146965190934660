import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import { HomePage } from "./pages/HomePage/HomePage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import { Layout } from "./components/Layout/Layout";
import { MainPage } from "./pages/MainPage/MainPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import { UserContext, UserContextProps } from "./contexts/UserContext";
import React from "react";
import { jwtDecode } from "jwt-decode";
import MVP from "./pages/MVP/MVP";
import SeparationTool from "./pages/SeparationToolPage/SeparationToolPage";

const mvpMode = process.env.REACT_APP_MVP_MODE === "true";

function App() {
  const { setUserToken } = React.useContext(UserContext) as UserContextProps;

  React.useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token !== null) {
      const currentDate = new Date();
      const decoded = jwtDecode(token);
      if (decoded.exp && decoded.exp * 1000 > currentDate.getTime())
        setUserToken(token);
      else localStorage.removeItem("userToken");
    }
  }, [setUserToken]);

  return (
    <BrowserRouter>
      <div className="App disable-text-selection">
        <Routes>
          <Route path="/*" element={<Layout />}>
            <Route path="" element={<HomePage />} />
            <Route path="dashboard" element={<MainPage default="septool" />} />
            {mvpMode && (
              <Route
                path="project/:id"
                element={
                  <div className="main-page-container" style={{ justifyContent: 'center' }}>
                    <SeparationTool />
                  </div>
                }
              />
            )}
            <Route path="register" element={<RegisterPage />} />
            {!mvpMode && (
              <>
                <Route path="login" element={<LoginPage />} />
                <Route path="profil" element={<MainPage />} />
                <Route path="mvp" element={<MVP />} />
              </>
            )}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
