import "./SongInfos.css";
import DropdownArrow from "../../../../assets/icons/dropdown-arrow-icon.svg";
import DownloadIcon from "../../../../assets/icons/download-icon.svg";
import React from "react";
import { SplitButton } from "../../../../components/SplitButton/SplitButton";
import { theme } from "../../../../constants/theme";
import SongDetails from "../SongDetails/SongDetails";
import {
  InputsContext,
  InputsContextProps,
  ISong,
} from "../../../../contexts/InputsContext";
import CrossIcon from "../../../../assets/icons/cross-icon.svg";
import { computeTime, getDate } from "../../../../tools/computeTime";
import GenericDropdownBasic from "../../../../components/UI/GenericDropdown";
import PauseIcon from "../../../../assets/icons/pause-icon.svg";
import {
  PlayerContext,
  PlayerContextProps,
} from "../../../../contexts/PlayerContext";
import {
  ISepModel,
  SepModelsContext,
  SepModelsContextProps,
} from "../../../../contexts/SepModelsContext";
import JSZip from "jszip";
import saveAs from "file-saver";

interface SongInfosProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  input: ISong;
  globalDetail: boolean;
}

const SongInfos: React.FC<SongInfosProps> = (props) => {
  const [isDetailOpen, setIsDetailOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.globalDetail)
      setIsDetailOpen(true)
    else
      setIsDetailOpen(false);
  }, [props.globalDetail])

  return (
    <>
      {isDetailOpen ? (
        <div className="song-infos-container">
          <SongColumn
            input={props.input}
            setIsDetailOpen={setIsDetailOpen}
            isDetailOpen={isDetailOpen}
          />
          <SongDetails
            setOpenModal={props.setOpenModal}
            openModal={props.openModal}
            input={props.input}
          />
        </div>
      ) : (
        <SongColumn
          input={props.input}
          isDetailOpen={isDetailOpen}
          setIsDetailOpen={setIsDetailOpen}
        />
      )}
    </>
  );
};

interface SongColumnProps {
  setIsDetailOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDetailOpen: boolean;
  input: ISong;
}

export const SongColumn: React.FC<SongColumnProps> = (props) => {
  const { isPlaying, setIsPlaying, cleanUpUniPlayer } = React.useContext(
    PlayerContext
  ) as PlayerContextProps;
  const [sepModel, setSepModel] = React.useState<ISepModel | undefined>(
    undefined
  );
  const { modifySepModel, currentSong, setCurrentSong, removeInputFromAdded } =
    React.useContext(InputsContext) as InputsContextProps;
  const { sepModels, getModelIndex } = React.useContext(
    SepModelsContext
  ) as SepModelsContextProps;
  const [hover, setHover] = React.useState(false);

  React.useEffect(() => {
    if (sepModel) {
      modifySepModel(props.input, sepModel.title);
    }
  }, [sepModel]);

  const handleDownload = async () => {
    const tracks =
      props.input.outputs[getModelIndex(props.input.sepModel)].tracks;
    let originalTitle = props.input.title;
    if (props.input.title && props.input.title.endsWith(".wav"))
      originalTitle = props.input.title.slice(0, -4);
    else if (props.input.title && props.input.title.endsWith(".mp3"))
      originalTitle = props.input.title.slice(0, -4);
    if (tracks) {
      const zip = new JSZip();
      const requests = tracks.map((track) => {
        zip.file(originalTitle ? `${originalTitle}_${track.name}` : `${track.name}`, track.blob);
        return null;
      });
      await Promise.all(requests);
      await zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, "tracks.zip");
      });
    }
  };

  return (
    <div
      className="song-column-container generic-medium-text"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{ width: "16%", gap: "8%" }} className="song-column-start">
        {hover && (
          <img
            src={CrossIcon}
            alt="remove-track"
            style={{ cursor: "pointer" }}
            onClick={() => {
              cleanUpUniPlayer();
              removeInputFromAdded(props.input);
            }}
          />
        )}
        <div
          className="song-column-button-container"
          onClick={() => {
            if (currentSong !== props.input) {
              cleanUpUniPlayer();
              setCurrentSong(props.input);
              setIsPlaying(true);
            } else {
              setIsPlaying(!isPlaying);
            }
          }}
        >
          <img
            src={
              isPlaying && currentSong === props.input
                ? PauseIcon
                : DropdownArrow
            }
            alt="play-button"
            style={{
              transform:
                isPlaying && currentSong === props.input ? "" : "rotate(90deg)",
              width: "40%",
            }}
          />
        </div>
        {props.input.title}
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "16%" }} className="song-column-start">
        {props.input.artist}
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "9%" }} className="song-column-start">
        {props.input.format}
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "9%" }} className="song-column-start">
        {getDate(props.input.date ? props.input.date : 0)}
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "9%" }} className="song-column-start">
        {computeTime(props.input.duration)}
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div className="song-column-center" style={{ width: "14%" }}>
        <GenericDropdownBasic
          position="bottom"
          className="song-column-sep-model"
          default={props.input.sepModel}
          items={sepModels}
          currentSelectedItem={props.input.sepModel ?? "Select a model"}
          setItemCallback={setSepModel}
        />
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "10%" }} className="song-column-center">
        <SplitButton
          shadow
          gap="0"
          color="#3F4043"
          hoverColor={theme.palette.purple}
          width="90%"
          height="60%"
          input={props.input}
          withAnim
        />
      </div>
      <div
        style={{ height: "2vh", width: "2px", backgroundColor: "#48484D" }}
      ></div>
      <div style={{ width: "12%" }} className="song-column-center">
        <div className="song-column-download" onClick={handleDownload}>
          <div className="generic-medium-text" style={{ fontSize: "0.8vw" }}>
            Download all
          </div>
          <img
            className="song-column-download-button"
            src={DownloadIcon}
            alt="download-icon"
          />
        </div>
      </div>
      <div
        style={{ width: "3.5%", cursor: "pointer" }}
        className="song-column-center"
        onClick={() => props.setIsDetailOpen(!props.isDetailOpen)}
      >
        <img
          style={{
            width: "0.6vw",
            transform: !props.isDetailOpen ? "rotate(180deg)" : "",
          }}
          src={DropdownArrow}
          alt="play-button"
        />
      </div>
    </div>
  );
};

export default SongInfos;
