import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserContextProvider } from "./contexts/UserContext";
import { InputsContextProvider } from "./contexts/InputsContext";
import { SepModelsContextProvider } from "./contexts/SepModelsContext";
import { MultiPlayerContextProvider } from "./contexts/MultiPlayerContext";
import { PlayerContextProvider } from "./contexts/PlayerContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <UserContextProvider>
    <SepModelsContextProvider>
      <InputsContextProvider>
        <MultiPlayerContextProvider>
          <PlayerContextProvider>
            <App />
          </PlayerContextProvider>
        </MultiPlayerContextProvider>
      </InputsContextProvider>
    </SepModelsContextProvider>
  </UserContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
