import React, { CSSProperties } from "react";
import "./Generic.css";
import SimpleSVGComponent from "./SimpleSVGComponent";
import ArrowIcon from "../../assets/icons/arrow-icon.svg";

export interface GenericButtonProps {
  text: string;
  className?: string;
  color?: string;
  onClick?: () => void;
  padding?: string;
  hoverColor?: string;
  arrowIcon?: boolean;
  width?: string;
  height?: string;
  hoverPadding?: string;
  textClassName?: string;
  withoutHover?: boolean;
  icon? : string;
  iconHeight?: string;
  iconWidth?: string;
  borderRadius?: string;
  style?: CSSProperties;
  endIcon? : string;
  justifyContent? : string;
  submit?: boolean;
}

export const GenericButton = (props: GenericButtonProps) => {
    const [isHover, setIsHover] = React.useState(false);

  return (
    <div
      className={(props.withoutHover ? "generic-button-no-hover " : "generic-button ") + props.className}
      style={{ backgroundColor: (isHover ? props.hoverColor : props.color), padding: (isHover ? props.hoverPadding : props.padding), width: props.width, height: props.height, borderRadius: props.borderRadius, justifyContent: props.justifyContent , ...props.style}}
      onClick={props.onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {props.icon && <SimpleSVGComponent height={props.iconHeight} width={props.iconWidth} icon={props.icon} alt="button-icon" />}
      {props.submit && <input type="submit" value={props.text} className="generic-small-bold-text" />}
      {!props.submit && <div className={props.textClassName ?? "generic-small-bold-text"}>{props.text}</div>}
      {isHover && props.arrowIcon && <SimpleSVGComponent icon={ArrowIcon} alt="arrow-icon" width="10%" height="auto" />}
      {props.endIcon && <SimpleSVGComponent height={props.iconHeight} width={props.iconWidth} icon={props.endIcon} alt="button-icon" />}
    </div>
  );
};
