import React from "react";
import DropdownIcon from "../../../../assets/icons/dropdown-arrow-icon.svg";
import SepModelIcon from "../../../../assets/icons/separation-model-icon.svg";
import VocalsIcon from "../../../../assets/icons/vocals-icon.svg";
import PencilIcon from "../../../../assets/icons/pencil-icon.svg";
import ShareIcon from "../../../../assets/icons/share-icon.svg";
import DownloadIcon from "../../../../assets/icons/download-icon.svg";
import LoadingGif from "../../../../assets/gifs/loading.gif";
import "./SongDetails.css";
import { GenericButton } from "../../../../components/UI/GenericButton";
import { SplitButton } from "../../../../components/SplitButton/SplitButton";
import ShareModal from "../ShareModal/ShareModal";
import {
  ISong,
  ITrack,
  InputsContext,
  InputsContextProps,
} from "../../../../contexts/InputsContext";
import {
  SepModelsContext,
  SepModelsContextProps,
} from "../../../../contexts/SepModelsContext";
import SimpleSVGComponent from "../../../../components/UI/SimpleSVGComponent";
import { saveAs } from "file-saver";
import { theme } from "../../../../constants/theme";

interface SongDetailsProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  input: ISong;
}

const SongDetails: React.FC<SongDetailsProps> = (props) => {
  const shareRef = React.useRef(null);
  const { getModelIndex, sepModels } = React.useContext(
    SepModelsContext
  ) as SepModelsContextProps;
  const { modifySepModel, modifyTrackTitle } = React.useContext(
    InputsContext
  ) as InputsContextProps;

  const sepModelIndex = React.useMemo(
    () => getModelIndex(props.input.sepModel),
    [props.input.sepModel, getModelIndex]
  );

  const NoSeparation: React.FC = () => {
    return (
      <div className="no-separation-container">
        <img
          src={LoadingGif}
          alt="loading-gif"
          style={{
            height: "auto",
            width: "20%",
            transform: "translate(0, 50%)",
          }}
        />
        <div className="generic-big-bold-text">
          No separation yet with this model
        </div>
        <SplitButton width="40%" input={props.input} withAnim />
      </div>
    );
  };

  interface SeparationLineProps {
    track: ITrack;
  }

  const SeparationLine: React.FC<SeparationLineProps> = ({ track }) => {
    const outputTypeIconPath =
      "assets/septool/output-types/Icon_" + track.type + ".svg";
      const [isTextInputMode, setIsTextInputMode] = React.useState(false);

    const handleDownload = () => {
      saveAs(track.blob, track.name);
    }

    React.useEffect(() => {
      if (isTextInputMode) {
        document.getElementById("output-track-name")?.focus()
      }
    }, [isTextInputMode])

    return (
      <div className="separation-line-container">
        <div className="separation-line">
          <div className="separation-line-infos">
            <div className="separation-line-name-container">
                {isTextInputMode ? (
                  <input
                    id="output-track-name"
                    className="bassnet-audio-track-title-input generic-medium-text"
                    type="text"
                    defaultValue={track.name}
                    onChange={(e) => modifyTrackTitle(props.input, track, e.target.value)}
                    style={{
                      backgroundColor: theme.palette.darkGrey,
                      color: theme.palette.white,
                      border: "transparent",
                      outline: "none",
                    }}
                    maxLength={30}
                  />
                ) : (
                  <div className="generic-medium-text">{track.name}</div>
                )}
              <img src={PencilIcon} alt="modify-name-icon" onClick={() => setIsTextInputMode(!isTextInputMode)} />
            </div>
            <div
              style={{
                height: "2vh",
                width: "1px",
                backgroundColor: "#48484D",
              }}
            ></div>
            <SimpleSVGComponent
              icon={outputTypeIconPath}
              alt={"icon-" + track.type}
              width="4.5vw"
              height="4vh"
            />
            <div
              style={{
                height: "2vh",
                width: "1px",
                backgroundColor: "#48484D",
              }}
            ></div>
          </div>
          <div className="separation-line-download" onClick={handleDownload}>
            <img
              src={DownloadIcon}
              alt="download-button"
              className="separation-line-download-button"
            />
          </div>
        </div>
        <div className="underline"></div>
      </div>
    );
  };

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      {props.openModal && <div className="overlay"></div>}
      {props.openModal && (
        <ShareModal shareRef={shareRef} setOpenModal={props.setOpenModal} />
      )}
      <div className="song-details-container">
        <div className="song-details-header">
          <div className="song-details-header-left">
            <img src={SepModelIcon} alt="sep-model-icon" />
            <div className="generic-medium-bold-text">Separation model</div>
            <div className="song-details-header-left-model-container">
              <div className="generic-medium-bold-text">
                {props.input.sepModel ?? "Select a model"}
              </div>
            </div>
            <div
              className="song-details-header-button"
              onClick={() => {
                modifySepModel(
                  props.input,
                  sepModels[
                    sepModelIndex !== 0
                      ? sepModelIndex - 1
                      : sepModels.length - 1
                  ].title
                );
              }}
            >
              <img
                src={DropdownIcon}
                alt="left-icon"
                style={{ transform: "rotate(-90deg)", width: "35%" }}
              />
            </div>
            <div
              className="song-details-header-button"
              onClick={() => {
                modifySepModel(
                  props.input,
                  sepModels[(sepModelIndex + 1) % sepModels.length].title
                );
              }}
            >
              <img
                src={DropdownIcon}
                alt="right-icon"
                style={{ transform: "rotate(90deg)", width: "35%" }}
              />
            </div>
          </div>
          <div
            ref={shareRef}
            className="song-details-header-right"
            onClick={() => props.setOpenModal(true)}
          >
            <img src={ShareIcon} alt="share-button" />
          </div>
        </div>
        <div className="song-details-main">
          <div className="underline"></div>
          {props.input.outputs[sepModelIndex].tracks ? (
            <>
              {props.input.outputs[sepModelIndex].tracks?.map(
                (track, index) => (
                  <SeparationLine track={track} key={index} />
                )
              )}
            </>
          ) : (
            <NoSeparation />
          )}
        </div>
      </div>
    </div>
  );
};

export default SongDetails;
