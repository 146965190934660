import React from "react";
import "./ShareModal.css";
import ArrowIcon from "../../../../assets/icons/arrow-icon.svg";
import MailIcon from "../../../../assets/icons/mail-icon.svg";
import ValidationTickIcon from "../../../../assets/icons/validation-tick.svg";
import { GenericButton } from "../../../../components/UI/GenericButton";

interface ShareModalProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  shareRef: React.MutableRefObject<null | HTMLDivElement>;
}

const ShareModal: React.FC<ShareModalProps> = (props) => {
  const divRef = React.useRef<null | HTMLDivElement>(null);
  const [sentMail, setSentMail] = React.useState(false);

  React.useEffect(() => {
    if (sentMail) {
      setTimeout(() => {
        props.setOpenModal(false);
      }, 3000);
    }
  }, [sentMail, props]);

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (
        divRef.current &&
        props.shareRef.current &&
        !divRef.current.contains(event.target as Node) &&
        !props.shareRef.current.contains(event.target as Node)
      ) {
        props.setOpenModal(false);
      }
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [props]);

  return (
    <>
      {sentMail ? (
        <div className="sent-mail-modal">
          <div className="share-modal-button-container">
            <img src={ValidationTickIcon} alt="validation-tick-icon" />
          </div>
          <div className="generic-small-bold-text">
            Your track has been shared by mail!
          </div>
        </div>
      ) : (
        <div ref={divRef} className="share-modal-container">
          <div className="share-modal-header">
            <div className="share-modal-button-container">
              <img src={ArrowIcon} alt="share-arrow-icon" />
            </div>
            <div className="generic-small-bold-text">Send your track</div>
          </div>
          <div className="share-modal-main-container">
            <div className="share-modal-mail-header">
              <img src={MailIcon} alt="mail-icon" />
              <div className="generic-small-text">Email to</div>
              <div className="share-modal-mail generic-small-bold-text">
                sonycsl@paris.com
              </div>
            </div>
            <div className="generic-small-text">
              “Hey, i have just separated this track{" "}
              <span className="generic-small-bold-italic-text">
                “Le chemin - KYO“
              </span>{" "}
              using sound separation by Sony, listen to it here!“
            </div>
          </div>
          <div className="share-modal-footer">
            <GenericButton
              onClick={() => {
                setSentMail(true);
              }}
              text="Send mail"
              arrowIcon
              color="#3F4043"
              hoverColor="#1A191E"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ShareModal;
